import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { PresetsSelector } from "../../basic/PresetsSelector/PresetsSelector";
import { generateDescription, checkStatus } from "../../../utils/apiCalls";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { CustomCard } from "../../basic/CustomCard/CustomCard";

const useStyles = makeStyles((theme) => ({
  stepHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  },
  jsonActions: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    marginBottom: "16px",
    whiteSpace: "nowrap",
  },
  subtitle: {
    marginBottom: "16px",
  },
  loader: {
    marginTop: "16px",
  },
  status: {
    marginTop: "24px",
    marginBottom: "16px",
  },
}));

export const Generate = ({
  json,
  setSelectedPreset,
  selectedPreset,
  setStep,
  setAlert,
  attributes,
}) => {
  const classes = useStyles();
  const [jobId, setJobId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const jsonLength = JSON.parse(json).length;

  const handleGenerateClick = () => {
    if (!selectedPreset) {
      setAlert({
        type: "error",
        text: "Please select preset to generate file",
      });
      return;
    }
    const requestData = {
      preset_id: selectedPreset,
      products: JSON.parse(json),
    };
    generateDescription(requestData).then((res) =>
      setJobId(res.data.job_status_id)
    );
  };

  useEffect(() => {
    let statusCheckInterval;
    if (jobId) {
      setIsLoading(true);
      statusCheckInterval = setInterval(
        () =>
          checkStatus(jobId).then(({ data }) => {
            if (data.status === "finished") {
              setIsLoading(false);
              clearInterval(statusCheckInterval);
              setAlert({
                type: "success",
                text: `File was generated successfully`,
              });
            }
          }),
        2000
      );
    }

    return () => clearInterval(statusCheckInterval);
  }, [jobId]);

  return (
    <>
      <div className={classes.stepHeader}>
        <PresetsSelector
          setAlert={setAlert}
          setSelectedPreset={setSelectedPreset}
          selectedPreset={selectedPreset}
          allowActions={false}
          attributes={attributes}
        />
        <Button variant="contained" color="primary" onClick={() => setStep(5)}>
          Proceed to Review
        </Button>
      </div>
      <Divider style={{ margin: "0 -48px 32px" }} />
      <Grid container>
        <Grid item>
          <CustomCard
            header={
              <Typography className={classes.subtitle}>
                There are {jsonLength} items in the current json file to
                generate text for.
              </Typography>
            }
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateClick}
            >
              Generate Description
            </Button>
            {isLoading && <LinearProgress className={classes.loader} />}
          </CustomCard>
        </Grid>
      </Grid>
    </>
  );
};
