import React from "react";
import useStyles from "./ImageTaggingControls.style";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { fileIdSelector, startTagging, imagesSelector } from "../imageTaggingSlice";
import { useDispatch, useSelector } from "react-redux";

const ImageTaggingControls = ({}) => {
  const c = useStyles();
  const dispatch = useDispatch();

  const images = useSelector(imagesSelector);
  const { zipUrl } = useSelector(fileIdSelector);

  return (
    <Card className={c.root}>
      <Button
        color="primary"
        variant="contained"
        disabled={!images.length}
        onClick={() => dispatch(startTagging())}
      >
        Start Tagging
      </Button>
      <Button
        disabled={!zipUrl}
        color="primary"
        variant="contained"
        href={zipUrl}
      >
        Download all tagged images
      </Button>
    </Card>
  );
};

export default ImageTaggingControls;
