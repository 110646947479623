import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { request } from "../../utils/request";

export const DEFAULT_COLLECTION_ID = 1;

const collectionsAdapter = createEntityAdapter({
  sortComparer: (a, b) => (a.id < b.id ? -1 : 1),
});

const initialState = collectionsAdapter.getInitialState({
  selectedId: DEFAULT_COLLECTION_ID,
  status: "idle",
  error: null,
});

export const fetchCollections = createAsyncThunk(
  "collections/fetchCollections",
  async () => {
    const response = await request({
      method: "GET",
      url: `/collections`,
    });
    return response.data;
  }
);

export const addCollection = createAsyncThunk(
  "collections/addCollection",
  async (name, { rejectWithValue }) => {
    try {
      const response = await request({
        method: "POST",
        url: `/collections`,
        data: JSON.stringify({
          name,
        }),
      });

      return response.data;
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.errors?.name?.[0] || "Something went wrong"
      );
    }
  }
);

export const editCollection = createAsyncThunk(
  "collections/editCollection",
  async ({ id, name }, { rejectWithValue }) => {
    try {
      const response = await request({
        method: "PUT",
        url: `/collections/${id}`,
        data: JSON.stringify({
          name,
        }),
      });

      return response.data;
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.errors?.name?.[0] || "Something went wrong"
      );
    }
  }
);

export const deleteCollection = createAsyncThunk(
  "collections/deleteCollection",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      await request({
        method: "DELETE",
        url: `/collections/${id}`,
      });
    } catch (e) {
      dispatch(fetchCollections());

      return rejectWithValue(
        e.response?.data?.errors?.name?.[0] || "Something went wrong"
      );
    }
  }
);

const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    selectedIdChanged: (state, action) => {
      state.selectedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollections.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCollections.fulfilled, (state, action) => {
        state.status = "succeeded";
        collectionsAdapter.setMany(state, action.payload);
      })
      .addCase(fetchCollections.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    builder.addCase(addCollection.fulfilled, (state, action) => {
      collectionsAdapter.upsertMany(state, action.payload);
    });

    builder.addCase(editCollection.fulfilled, (state, action) => {
      collectionsAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(deleteCollection.pending, (state, action) => {
      collectionsAdapter.removeOne(state, action.meta.arg.id);
    });
  },
});

export const {
  selectAll: selectAllCollections,
  selectById: selectCollectionById,
  selectIds: selectCollectionIds,
  selectEntities: selectCollectionEntities,
  selectTotal: selectCollectionsTotal,
} = collectionsAdapter.getSelectors((state) => state.collections);

export const isCollectionsLoadingSelector = (state) =>
  state.collections.status === "loading" || state.collections.status === "idle";
export const collectionsErrorSelector = (state) => state.collections.error;

export const selectedCollectionIdSelector = (state) =>
  state.collections.selectedId;
export const selectedCollectionSelector = createSelector(
  [selectCollectionEntities, (state) => state.collections.selectedId],
  (entities, id) => entities[id]
);

export const { selectedIdChanged } = collectionsSlice.actions;
export default collectionsSlice.reducer;
