import axios from "axios";

axios.defaults.withCredentials = true;

export const baseURL = window.location.host;

const request = axios.create({
  baseURL: `https://${baseURL}/api`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const onError = (err) => {
  const status = err?.response?.status;
  if (status === 401 || status === 419) {
    window.localStorage.removeItem("user");
    document.location.reload();
  }
  return Promise.reject(err);
};

request.interceptors.response.use((res) => res, onError);

export { request };
