import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: "#424450",
    color: "#fff",
  },
  step: {
    color: "#fff",
  },
}));

export const Steps = ({ disabled, step, setStep, stepsList }) => {
  const classes = useStyles();

  return (
    <Stepper
      activeStep={step - 1}
      nonLinear
      orientation="vertical"
      className={classes.stepper}
    >
      {stepsList.map((label, index) => {
        return (
          <Step key={label}>
            <StepButton disabled={disabled} onClick={() => setStep(index + 1)}>
              {label}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};
