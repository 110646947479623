import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles((theme) => ({
  subTitle: {
    margin: "12px 0 8px",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
}));

export const RequestEngineTooltip = ({ children, tooltipContent, open }) => {
  const { name, description, strengths } = tooltipContent || {};
  const classes = useStyles();

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: "16px",
      border: "1px solid #dadde9",
      padding: "16px",
    },
  }))(Tooltip);

  return (
    <CustomTooltip
      placement="left"
      title={
        <>
          <Typography variant={"subtitle1"} className={classes.subTitle}>
            {name}
          </Typography>
          <Typography className={classes.text}>{description}</Typography>
          <Typography variant={"subtitle1"} className={classes.subTitle}>
            STRENGTHS
          </Typography>
          <Typography className={classes.text}>{strengths}</Typography>
        </>
      }
      open={open}
    >
      {children}
    </CustomTooltip>
  );
};
