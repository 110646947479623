import React, { useState } from "react";
import useStyles from "./ImageUploader.style";
import Button from "@material-ui/core/Button";
import {
  clearAllImages,
  imagesSelector,
  uploadImages,
} from "../imageTaggingSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "../../../components/basic/CustomAlert";

const ImageUploader = () => {
  const c = useStyles();
  const dispatch = useDispatch();
  const images = useSelector(imagesSelector);
  const [filesLength, setFilesLength] = useState();

  const handleUpload = (e) => {
    const files = e.target.files;
    if (files.length > 100) {
      setFilesLength(files.length);
    } else {
      dispatch(uploadImages(files));
    }
  };

  return (
    <>
      <div className={c.root}>
        <p className={c.title}>Select one or more images to tag</p>

        <div className={c.controls}>
          <label htmlFor="contained-button-file">
            <input
              style={{ display: "none" }}
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleUpload}
            />
            <Button
              className={c.button}
              color="primary"
              component={"span"}
              variant="outlined"
            >
              Select
            </Button>
          </label>
          {Boolean(images.length) && (
            <Button
              className={c.button}
              color="primary"
              component={"span"}
              variant="contained"
              onClick={() => dispatch(clearAllImages())}
            >
              Clear all
            </Button>
          )}
        </div>
      </div>
      <CustomAlert isShow={filesLength} type="error">Can't have more than 100 files!</CustomAlert>
    </>
  );
};

export default ImageUploader;
