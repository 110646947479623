import { Modal } from "../../basic/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { useState, useReducer, useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import {
  getWebinarsList,
  createWebinar,
  updateWebinar,
  deleteWebinar,
  getWebinar,
} from "../../../utils/apiCalls";
import moment from "moment";

const initWebinarSettings = {
  description: "",
  starts_at: "",
  link: "",
  status: "",
  id: "",
};

const webinarReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "id":
      return { ...state, id: payload };
    case "status":
      return { ...state, status: payload };
    case "description":
      return { ...state, description: payload };
    case "link":
      return { ...state, link: payload };
    case "dateTime":
      return { ...state, starts_at: payload };
    case "webinar":
      return payload;
    default:
      throw new Error();
  }
};

const useStyles = makeStyles(() => ({
  input: {
    width: "100%",
    marginBottom: "32px",
    fontSize: "18px",
  },
  label: {
    marginBottom: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const WebinarsModal = ({ isOpen, setIsOpen, setAlert }) => {
  const [state, dispatch] = useReducer(webinarReducer, initWebinarSettings);
  const classes = useStyles();
  const [webinarsList, setWebinarsList] = useState([]);

  useEffect(() => {
    if (isOpen) {
      updateWebinarsList();
    }
  }, [isOpen]);

  const updateWebinarsList = () => {
    getWebinarsList().then((res) => setWebinarsList(res.data));
  };

  const handleChange = (type, event) => {
    dispatch({ type, payload: event.target.value });
  };

  const validateWebinar = () => {
    const hasEmptyFields = Object.entries(state).some(
      ([key, value]) => key !== "id" && !value
    );

    if (hasEmptyFields) {
      setAlert({ type: "error", text: "Please fill all fields" });
      return false;
    } else {
      return true;
    }
  };

  const handleCreateNewClick = () => {
    if (validateWebinar()) {
      createWebinar(state).then(({ data }) => {
        setWebinarsList(data.webinars)
        dispatch({ type: "id", payload: data.webinar.id });
        setAlert({ type: "success", text: "Webinar created" });
      });
    }
  };

  const handleSaveClick = () => {
    if (validateWebinar()) {
      updateWebinar(state.id, state).then(() => {
        setAlert({ type: "success", text: "Webinar updated" });
      });
    }
  };

  const handleWebinarSelect = (id) => {
    getWebinar(id).then(({ data }) => {
      dispatch({ type: "webinar", payload: data });
    });
  };

  const handleDeleteWebinar = () => {
    deleteWebinar(state.id).then(({data}) => {
      setWebinarsList(data)
      dispatch({ type: "webinar", payload: initWebinarSettings });
      setAlert({ type: "success", text: "Webinar deleted" });
    });
  };

  const getModalContent = () => {
    return (
      <form className={classes.form}>
        <InputLabel className={classes.label}>Webinar</InputLabel>
        <Select
          className={classes.input}
          onChange={(e) => handleWebinarSelect(e.target.value)}
          value={state.id}
          input={<OutlinedInput />}
        >
          {webinarsList.map(({ starts_at, id }) => (
            <MenuItem value={id} key={id}>
              {moment(starts_at).format("DD.MM.YYYY, HH:mm")}
            </MenuItem>
          ))}
        </Select>
        <TextField
          label="Date and time"
          type="datetime-local"
          value={state.starts_at}
          className={classes.input}
          onChange={(e) => handleChange("dateTime", e)}
          style={{ maxWidth: "175px" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.input}
          label="Description"
          multiline
          value={state.description}
          onChange={(e) => handleChange("description", e)}
          variant="outlined"
        />
        <TextField
          className={classes.input}
          label="Link to meeting"
          multiline
          value={state.link}
          onChange={(e) => handleChange("link", e)}
          variant="outlined"
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <RadioGroup
            value={state.status}
            onChange={(e) => handleChange("status", e)}
          >
            <FormControlLabel value="opened" control={<Radio />} label="Open" />
            <FormControlLabel
              value="closed"
              control={<Radio />}
              label="Closed"
            />
          </RadioGroup>
        </FormControl>
      </form>
    );
  };

  const getModalActions = () => {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<SaveIcon />}
          disabled={!state.id}
          onClick={handleSaveClick}
        >
          Save
        </Button>
        <Button
          onClick={handleCreateNewClick}
          variant="outlined"
          color="primary"
          startIcon={<CreateNewFolderIcon />}
        >
          Create New
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disabled={!state.id}
          startIcon={<DeleteForeverIcon />}
          onClick={handleDeleteWebinar}
        >
          Delete
        </Button>
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Manage webinars"}
      content={getModalContent()}
      actions={getModalActions()}
    />
  );
};
