import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useCallback } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "24px",
  },
  input: {
    width: "90px",
  },
  sliderContainer: {
    display: "flex",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: "14px",
    color: "black",
    marginRight: "6px",
  },
}));

export const RequestSlider = ({
  label,
  name,
  value,
  minValue,
  maxValue,
  valueStep,
  setRequestSettings,
  handleChange,
  tooltipText,
}) => {
  const classes = useStyles();

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: "16px",
      border: "1px solid #dadde9",
      padding: "16px",
    },
  }))(Tooltip);

  const debounce = (fn, ms) => {
    let timeout;

    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => fn(...args), ms);
    };
  };

  const handleSliderChange = useCallback(
    debounce((e, newValue) => {
      handleChange(newValue, name);
    }),
    []
  );

  const handleInputChange = (e) => {
    const newValue = e.target.value || minValue;
    const checkLength = newValue.length > 4 ? +newValue.slice(0, 4) : +newValue;
    const checkedMinValue = checkLength < minValue ? minValue : checkLength;
    const checkedMaxValue =
      checkedMinValue > maxValue ? maxValue : checkedMinValue;
    handleChange(checkedMaxValue, name);
  };

  return (
    <div className={classes.root}>
      <div className={classes.labelContainer}>
        <InputLabel className={classes.label}>{label}</InputLabel>
        {tooltipText && (
          <CustomTooltip placement="left" title={tooltipText}>
            <InfoOutlinedIcon fontSize="small" />
          </CustomTooltip>
        )}
      </div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs className={classes.sliderContainer}>
          <Slider
            value={value}
            onChange={handleSliderChange}
            min={minValue}
            max={maxValue}
            step={valueStep}
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.input}
            value={value}
            onChange={handleInputChange}
            variant="outlined"
            inputProps={{
              step: valueStep,
              min: minValue,
              max: maxValue,
              type: "number",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
