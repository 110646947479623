import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  alert: {
    position: "fixed",
    right: "20px",
    bottom: "20px",
    "zIndex": 9999,
  }
});

export default useStyles;