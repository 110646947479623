import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import { IconButton, Button, TextField } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ReactJson from "react-json-view";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import moment from "moment";
import { CustomCard } from "../../basic/CustomCard/CustomCard";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { getFilesList, updateJSONDescription } from "../../../utils/apiCalls";
import ReviewTranslate from "./ReviewTranslate";
import { baseURL } from "../../../utils/request";
import { useSelector } from "react-redux";
import { selectedCollectionIdSelector } from "../../../features/collections/collectionsSlice";

const useStyles = makeStyles(() => ({
  titleBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "38px",
  },
  jsonActions: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    marginBottom: "24px",
    whiteSpace: "nowrap",
  },
  subtitle: {
    lineHeight: "48px",
    whiteSpace: "nowrap",
    marginRight: "auto",
  },
  jsonContainer: {
    minWidth: "500px",
    maxWidth: "500px",
  },
  reactionsContainer: {
    minWidth: "500px",
  },
  editInput: {
    padding: 0,
    lineHeight: 1.43,
  },
  loader: {
    display: "block",
    margin: "0 auto",
  },
  actionButton: {
    marginLeft: "12px",
    border: "1px solid",
  },
  reactionActions: {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",

    ":first-child": {
      marginRight: "auto",
    },
  },
  reactionIcon: {
    padding: "12px",
    marginLeft: "12px",
    display: "flex",
  },
  reactionButton: {
    border: "1px solid",
    marginLeft: "12px",
  },
  fileActionButton: {
    marginLeft: "16px",
  },
}));

const VALUE_LENGTH_TO_COLLAPSE = 20;

export const ReviewItem = ({
  content,
  createdAt,
  fileId,
  languages,
  langList,
  preset,
  handleDeleteFile,
  handleRate,
  handleRateUndo,
  setFilesList,
  setAlert,
}) => {
  const classes = useStyles();

  const [jsonIndex, setJsonIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState("");

  const selectedCollectionId = useSelector(selectedCollectionIdSelector);

  const jsonLength = content.length;
  const currentJson = content[jsonIndex];
  const isLike = currentJson.gpt_3_like === 1;
  const isDislike = currentJson.gpt_3_like === 0;
  const isRated = isLike || isDislike;
  const uuid = currentJson.gpt_3_uuid;

  const onDownloadClick = () => {
    window.open(`https://${baseURL}/api/files/${fileId}`);
  };

  const getFilesListWithEditedDescription = (filesList) => {
    return filesList.map((item) => {
      const setEditedDescription = () => {
        const contentCopy = content.slice();
        contentCopy[jsonIndex].gpt_3_description = editText;

        return contentCopy;
      };

      return item.id === fileId
        ? {
            ...item,
            content: setEditedDescription(),
          }
        : item;
    });
  };

  const setEditTextOnSwitch = () => {
    setEditText(isEditing ? currentJson.gpt_3_description : "");
  };

  useEffect(setEditTextOnSwitch, [isEditing]);

  const handleIsEditing = () => {
    const hasChanges =
      !!editText.trim().length &&
      currentJson.gpt_3_description !== editText.trim();

    if (isEditing && hasChanges) {
      updateJSONDescription({
        fileId,
        gpt_3_uuid: currentJson.gpt_3_uuid,
        description: editText,
      })
        .then(() => getFilesList({ collectionId: selectedCollectionId }))
        .then((res) => {
          setFilesList(res.data);
        })
        .catch(() => {
          setAlert({ type: "error", text: "Something went wrong!" });
        });

      setFilesList(getFilesListWithEditedDescription);
      setIsEditing(false);

      return;
    }

    setIsEditing((prev) => !prev);
  };

  const handleEditTextChange = (e) => {
    setEditText(e.target.value);
  };

  const getReactions = () => {
    return isRated ? (
      <>
        {isDislike ? (
          <IconButton
            className={classes.reactionButton}
            color="primary"
            onClick={() => handleRateUndo(currentJson.gpt_3_review_id)}
          >
            <ThumbDownIcon />
          </IconButton>
        ) : (
          <IconButton
            className={classes.reactionButton}
            color="primary"
            onClick={() => handleRateUndo(currentJson.gpt_3_review_id)}
          >
            <ThumbUpIcon />
          </IconButton>
        )}
      </>
    ) : (
      <>
        <IconButton
          className={classes.reactionButton}
          color="primary"
          onClick={() => handleRate(false, fileId, uuid)}
        >
          <ThumbDownOutlinedIcon />
        </IconButton>
        <IconButton
          className={classes.reactionButton}
          color="primary"
          onClick={() => handleRate(true, fileId, uuid)}
        >
          <ThumbUpOutlinedIcon />
        </IconButton>
      </>
    );
  };

  return (
    <>
      <div className={classes.titleBlock}>
        <Typography className={classes.title} variant="h5">
          Created by preset "{preset.name}"
          <span>
            {" "}
            (at {moment(createdAt).format("LT")} on the{" "}
            {moment(createdAt).format("DD.MM.YYYY")})
          </span>
        </Typography>
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={onDownloadClick}
            startIcon={<GetAppIcon />}
            className={classes.fileActionButton}
          >
            Download File
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDeleteFile}
            startIcon={<DeleteForeverIcon />}
            className={classes.fileActionButton}
          >
            Delete File
          </Button>
        </div>
      </div>

      <Grid container alignItems="stretch" spacing={8} wrap="nowrap">
        <Grid item className={classes.jsonContainer}>
          <CustomCard
            className={classes.card}
            header={
              <div className={classes.jsonActions}>
                <Typography variant="h6" className={classes.subtitle}>
                  Selected JSON Item - {jsonIndex + 1}
                </Typography>
                <IconButton
                  className={classes.actionButton}
                  color="primary"
                  disabled={jsonIndex === 0}
                  onClick={() => setJsonIndex((i) => i - 1)}
                >
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  className={classes.actionButton}
                  color="primary"
                  disabled={jsonIndex === jsonLength - 1}
                  onClick={() => setJsonIndex((i) => i + 1)}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </div>
            }
          >
            <ReactJson
              collapsed
              collapseStringsAfterLength={VALUE_LENGTH_TO_COLLAPSE}
              src={currentJson}
              name={false}
              indentWidth={4}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </CustomCard>
        </Grid>
        <Grid item className={classes.reactionsContainer} xs={10}>
          <CustomCard
            header={
              <div className={classes.reactionActions}>
                <Typography className={classes.subtitle} variant="h6">
                  Description for JSON Item - {jsonIndex + 1}
                </Typography>
                <Button
                  color={isEditing ? "secondary" : "primary"}
                  size={"large"}
                  variant={"outlined"}
                  onClick={handleIsEditing}
                >
                  {isEditing ? "Save" : "Edit"}
                </Button>
                {getReactions()}
              </div>
            }
          >
            {isEditing ? (
              <TextField
                autoFocus
                fullWidth
                multiline
                InputProps={{ className: classes.editInput }}
                maxRows={"Infinity"}
                value={editText}
                onChange={handleEditTextChange}
              />
            ) : (
              <div>{currentJson.gpt_3_description}</div>
            )}
          </CustomCard>
          <ReviewTranslate
            fileId={fileId}
            languages={languages}
            langList={langList}
            onSave={() => {
              getFilesList({ collectionId: selectedCollectionId })
                .then((res) => {
                  setFilesList(res.data);
                  setAlert({
                    type: "success",
                    text: "Successfully translated!",
                  });
                })
                .catch(() => {
                  setAlert({ type: "error", text: "Something went wrong!" });
                });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
