import { Modal } from "../../basic/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createUser } from "../../../utils/apiCalls";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  input: {
    width: "300px",
    marginBottom: "24px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const CreateUserModal = ({ isOpen, setIsOpen, setAlert }) => {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [emailError, setEmailError] = useState("");
  const [passwordsError, setPasswordsError] = useState("");
  const [showPasswords, setShowPasswords] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email || !password) {
      return;
    }

    if (!checkEmailPattern()) {
      setEmailError("Incorrect email format");
      return;
    }

    if (password !== repeatPassword) {
      setPasswordsError("Passwords don't match");
      return;
    }

    const userData = {
      email,
      password,
    };

    setIsLoading(true);

    await createUser(userData)
      .then(() => {
        setIsOpen(false);
        setAlert({ type: "success", text: "User was successfully created" });
      })
      .catch(
        (err) =>
          err?.response?.data?.errors?.email &&
          setEmailError("This email has already been taken")
      );
    setIsLoading(false);
  };

  const checkEmailPattern = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  useEffect(() => {
    setEmailError("");
  }, [email]);

  useEffect(() => {
    setPasswordsError("");
  }, [password, repeatPassword]);

  const getModalContent = () => (
    <form className={classes.form}>
      <FormControl className={classes.input}>
        <InputLabel htmlFor="email">Email</InputLabel>
        <Input
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={emailError}
        />
        {emailError && <FormHelperText error>{emailError}</FormHelperText>}
      </FormControl>
      <FormControl className={classes.input}>
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={passwordsError}
          type={showPasswords ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPasswords(!showPasswords)}
                edge="end"
              >
                {showPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {passwordsError && (
          <FormHelperText error>{passwordsError}</FormHelperText>
        )}
      </FormControl>
      <FormControl className={classes.input}>
        <InputLabel htmlFor="outlined-adornment-password">
          Repeat Password
        </InputLabel>
        <Input
          label="Repeat password"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          error={passwordsError}
          type={showPasswords ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPasswords(!showPasswords)}
                edge="end"
              >
                {showPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {passwordsError && (
          <FormHelperText error>{passwordsError}</FormHelperText>
        )}
      </FormControl>
    </form>
  );

  const getModalActions = () =>
    !isLoading ? (
      <>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Submit
        </Button>{" "}
      </>
    ) : (
      <CircularProgress size={24} />
    );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Create new user"}
      content={getModalContent()}
      actions={getModalActions()}
    />
  );
};
