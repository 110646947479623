import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    margin: "-24px 0",
    overflow: "hidden",
  },
  container: {
    maxHeight: 400,
  },
  headerCell: {
    fontWeight: 700,
    backgroundColor: "white",
  },
});

export default useStyles;
