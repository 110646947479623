import { useEffect, useState } from "react";
import { getLanguages } from "../utils/apiCalls";

const useLanguagesList = () => {
  const [langList, setLangList] = useState([])

  useEffect(() => {
    getLanguages().then(({ data }) => {
      setLangList(data)
    });
  }, [])

  return langList
}

export default useLanguagesList
