import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { Button, Typography } from "@material-ui/core";
import { useState } from "react";
import { RequestSlider } from "./RequestSlider";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 -8px",
    padding: "8px",
    border: "1px solid rgb(0 0 0 / 20%)",
  },
  input: {
    width: "100%",
    marginBottom: "16px",
  },
  title: {
    marginRight: '8px'
  },
  cardsContainer: {
    marginTop: "16px",
    display: "flex",
    flexWrap: "wrap",
  },
  label: {
    fontSize: "14px",
    color: "black",
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: "16px",
  }
}));

export const RequestLogitBiasInput = ({ values, setRequestSettings }) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [bias, setBias] = useState(0);

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: "16px",
      border: "1px solid #dadde9",
      padding: "16px",
    },
  }))(Tooltip);

  const onBiasSumbit = () => {
    setRequestSettings((settings) => ({
      ...settings,
      logitBias: [...settings.logitBias, { text: text, bias: bias }],
    }));
    setText("");
    setBias(0);
  };

  const handleDeleteBias = (i) => {
    setRequestSettings((settings) => ({
      ...settings,
      logitBias: settings.logitBias.filter((bias, index) => i !== index),
    }));
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>Logit bias</Typography>
        <CustomTooltip
          placement="left"
          title="How many completions to generate for each prompt. Note: Because this parameter generates many completions, it can quickly consume your token quota. Use carefully and ensure that you have reasonable settings for max_tokens and stop."
        >
          <InfoOutlinedIcon fontSize="small" />
        </CustomTooltip>
      </div>
      <InputLabel htmlFor="bias-text" className={classes.label}>
        Text for include/exclude
      </InputLabel>
      <Input
        id="bias-text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className={classes.input}
        multiline
      />
      <RequestSlider
        label="Probability"
        value={bias}
        minValue={-100}
        maxValue={100}
        valueStep={1}
        handleChange={(value) => setBias(value)}
        tooltipText={""}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={onBiasSumbit}
        startIcon={<AddIcon />}
      >
        Add
      </Button>
      {!!values.length && (
        <div className={classes.cardsContainer}>
          {values.map(({ text, bias }, i) => (
            <Chip
              key={text + i}
              label={`${text}: ${bias}`}
              onDelete={() => handleDeleteBias(i)}
            />
          ))}
        </div>
      )}
    </Paper>
  );
};
