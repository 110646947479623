import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: "15px 0",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  chipsRoot: {
    flex: 3,

    "& > *:last-child": {
      marginLeft: "auto",
      width: "auto",
    },
  },
  progress: {
    marginLeft: 5,
  },
});

export default useStyles;
