import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    background: "white",
    opacity: 0.7,
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    zIndex: 2,
  },
  text: {
    fontSize: "18px",
    fontWeight: "bold",
    // marginBottom: "36.5px",
  },
  progressRoot: {
    position: "absolute",
    height: "calc(100vh - 100px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
  },
  button: {
    position: "relative",
    top: "18px",
  },
});

export default useStyles;
