import axios from "axios";
import { request } from "./request";
import { requestSettingsNormalizer } from "./settingsNormalizer";

export const getPresetsRequest = ({ collectionId, isPresetsForLanding }) => {
  const setting = isPresetsForLanding ? 1 : 0;
  return request({
    method: "GET",
    url: `/presets?for_landing=${setting}&collectionId=${collectionId}`,
  });
};

export const getPresetSettingsRequest = ({ id, collectionId }) => {
  return request({
    method: "GET",
    url: `/presets/${id}?collectionId=${collectionId}`,
  });
};

export const getEngines = () => {
  return request({
    method: "GET",
    url: `/engines`,
  });
};

export const updatePresetRequest = ({ data, id, collectionId }) => {
  return request({
    method: "PUT",
    url: `/presets/${id}?collectionId=${collectionId}`,
    data: JSON.stringify(requestSettingsNormalizer(data)),
  });
};

export const updatePresetPropertyRequest = ({ data, id, collectionId }) => {
  return request({
    method: "PATCH",
    url: `/presets/${id}?collectionId=${collectionId}`,
    data: JSON.stringify(data),
  });
};

export const createPresetRequest = ({
  data,
  isPresetsForLanding,
  collectionId,
}) => {
  const setting = isPresetsForLanding ? 1 : 0;
  return request({
    method: "POST",
    url: `/presets?for_landing=${setting}&collectionId=${collectionId}`,
    data: JSON.stringify(requestSettingsNormalizer(data)),
  });
};

export const deletePresetRequest = (id) => {
  return request({
    method: "DELETE",
    url: `/presets/${id}`,
  });
};

export const getPreview = (data) => {
  return request({
    method: "POST",
    url: `/description/preview`,
    data: JSON.stringify(data),
  });
};

export const createUser = (data) => {
  return request({
    method: "POST",
    url: `/users`,
    data: JSON.stringify(data),
  });
};

export const generateDescription = (data) => {
  return request({
    method: "POST",
    url: `/description/generate`,
    data: JSON.stringify(data),
  });
};

export const checkStatus = (id, cancelToken) => {
  return request({
    method: "GET",
    url: `/jobs/${id}`,
    cancelToken,
  }).catch(() => ({ data: { status: "finished" } }));
};

export const createToken = () => {
  return request({
    method: "GET",
    url: `/auth/csrf-cookie`,
  });
};

export const login = (data) => {
  return request({
    method: "POST",
    url: `/auth/login`,
    data: JSON.stringify(data),
  });
};

export const logout = () => {
  return request({
    method: "POST",
    url: `/auth/logout`,
  });
};

export const getUsers = () => {
  return request({
    method: "GET",
    url: `/users`,
  });
};

export const deleteUser = (id) => {
  return request({
    method: "DELETE",
    url: `/users/${id}`,
  });
};

export const changePassword = (data) => {
  return request({
    method: "PUT",
    url: `/profile/change-password`,
    data: JSON.stringify(data),
  });
};

export const getJsonFromDatabase = (collectionId) => {
  return request({
    method: "GET",
    url: `/json?collectionId=${collectionId}`,
  });
};

export const saveJsonToDatabase = ({ collectionId, json }) => {
  return request({
    method: "POST",
    url: `/json`,
    data: { collectionId, json },
  });
};

export const getFilesList = ({ collectionId }) => {
  return request({
    method: "GET",
    url: `/files?collectionId=${collectionId}`,
  });
};

export const setRate = (data, fileId) => {
  return request({
    method: "POST",
    url: `/files/${fileId}/rate`,
    data: JSON.stringify(data),
  });
};

export const updateJSONDescription = ({ fileId, gpt_3_uuid, description }) => {
  return request({
    method: "PATCH",
    url: `/files/${fileId}`,
    data: JSON.stringify({
      gpt_3_uuid,
      description,
    }),
  });
};

export const translateFiles = ({ fileId, languages }) => {
  return request({
    method: "POST",
    url: `/files/${fileId}/translate`,
    data: JSON.stringify({ languages }),
  });
};

export const deleteRate = (reviewId) => {
  return request({
    method: "DELETE",
    url: `reviews/${reviewId}`,
  });
};

export const getPresetsChartsData = ({ collectionId }) => {
  return request({
    method: "GET",
    url: `/presets/statistics?collectionId=${collectionId}`,
  });
};

export const deleteFile = (id) => {
  return request({
    method: "DELETE",
    url: `/files/${id}`,
  });
};

export const getWebinarsList = () => {
  return request({
    method: "GET",
    url: `/webinars`,
  });
};

export const createWebinar = (data) => {
  return request({
    method: "POST",
    url: `/webinars`,
    data: JSON.stringify(data),
  });
};

export const updateWebinar = (id, data) => {
  return request({
    method: "PUT",
    url: `/webinars/${id}`,
    data: JSON.stringify(data),
  });
};

export const deleteWebinar = (id) => {
  return request({
    method: "DELETE",
    url: `/webinars/${id}`,
  });
};

export const getWebinar = (id) => {
  return request({
    method: "GET",
    url: `/webinars/${id}`,
  });
};

export const getLanguages = () => {
  return request({
    method: "GET",
    url: `/deepl-api/languages`,
  });
};

export const getRest = () => {
  return request({
    method: "GET",
    url: `/rest`,
  });
};

export const getPreviewImageWithPresets = (presets) => {
  return request({
    method: "POST",
    url: "/image-tagging/preview",
    data: JSON.stringify(presets),
  });
};

export const setNewPreset = (presets) => {
  return request({
    method: "POST",
    url: "/image-tagging/preset",
    data: JSON.stringify(presets),
  });
};
