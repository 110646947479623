import { makeStyles } from "@material-ui/core/styles";
import logo from "./logo.png";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { CreateUserModal } from "../../features/Auth/CreateUserModal";
import { DeleteUserModal } from "../../features/Auth/DeleteUserModal";
import { WebinarsModal } from "../../features/Webinars/WebinarsModal";
import { Steps } from "../Steps/Steps";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  isCollectionsLoadingSelector,
  selectCollectionsTotal,
} from "../../../features/collections/collectionsSlice";
import EditCollectionsModal from "../../../features/collections/EditCollectionsModal/EditCollectionsModal";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { IMAGE_UPLOAD_ROUTE } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    flexShrink: 0,
    backgroundColor: "#424450",
    color: "#fff",
  },
  titleContainer: {
    height: "64px",
    display: "flex",
    padding: "8px 0",
  },
  title: {
    fontSize: "24px",
    margin: "auto",
  },
  settingsButton: {
    marginTop: "auto",
  },
  logo: {
    margin: "auto",
    height: "100%",
  },
  item: {
    padding: "15px 10px",
    textAlign: "center",
    cursor: "pointer",
  },
}));

export const SidePanel = ({ step, setStep, setAlert, isAdmin, stepsList }) => {
  const classes = useStyles();
  const history = useHistory();

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isManageWebinarsModalOpen, setIsManageWebinarsModalOpen] =
    useState(false);
  const [isCollectionsModalOpen, setIsCollectionsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isCollectionsLoading = useSelector(isCollectionsLoadingSelector);
  const collectionsLength = useSelector(selectCollectionsTotal);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreateUserClick = () => {
    handleMenuClose();
    setIsCreateUserModalOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteUserClick = () => {
    handleMenuClose();
    setIsDeleteUserModalOpen(true);
  };

  const handleManageWebinarsClick = () => {
    handleMenuClose();
    setIsManageWebinarsModalOpen(true);
  };

  const handleCollectionsClick = () => {
    handleMenuClose();
    setIsCollectionsModalOpen(true);
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawer,
        }}
        anchor="left"
      >
        <div className={classes.titleContainer}>
          <img src={logo} alt="logo" className={classes.logo} />
        </div>
        <Divider />
        <Steps
          disabled={isCollectionsLoading}
          step={step}
          setStep={setStep}
          stepsList={stepsList}
        />
        <Divider />
        <Typography
          className={classes.item}
          onClick={() => {
            history.push(IMAGE_UPLOAD_ROUTE);
          }}
        >
          Image Tagging
        </Typography>
        {isAdmin && (
          <Button
            variant="contained"
            color="primary"
            className={classes.settingsButton}
            endIcon={<SettingsIcon />}
            onClick={handleMenu}
          >
            Settings
          </Button>
        )}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleCreateUserClick}>Create user</MenuItem>
          <MenuItem onClick={handleDeleteUserClick}>Delete user</MenuItem>
          <MenuItem onClick={handleManageWebinarsClick}>
            Manage webinars
          </MenuItem>
          {Boolean(collectionsLength) && (
            <MenuItem onClick={handleCollectionsClick}>Collections</MenuItem>
          )}
        </Menu>
      </Drawer>
      {isAdmin && (
        <>
          <CreateUserModal
            isOpen={isCreateUserModalOpen}
            setIsOpen={setIsCreateUserModalOpen}
            setAlert={setAlert}
          />
          <DeleteUserModal
            isOpen={isDeleteUserModalOpen}
            setIsOpen={setIsDeleteUserModalOpen}
            setAlert={setAlert}
          />
          <WebinarsModal
            isOpen={isManageWebinarsModalOpen}
            setIsOpen={setIsManageWebinarsModalOpen}
            setAlert={setAlert}
          />
          <EditCollectionsModal
            isOpen={isCollectionsModalOpen}
            setIsOpen={setIsCollectionsModalOpen}
          />
        </>
      )}
    </>
  );
};
