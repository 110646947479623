import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";

export const CreatePresetDialog = ({
  open,
  handleClose,
  handleCreatePreset,
  setAlert,
}) => {
  const [name, setName] = useState("");

  const handleOk = () => {
    if (!name) {
      setAlert({type: 'error', text: "Please enter preset name"});
      return;
    }
    setName('');
    handleClose();
    handleCreatePreset(name);
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        Save Preset as...
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Enter Preset Name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
