import { useDispatch, useSelector } from "react-redux";
import {
  selectAllCollections,
  selectedCollectionSelector,
  selectedIdChanged,
} from "../../../../features/collections/collectionsSlice";
import { Button, MenuItem } from "@material-ui/core";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";

const CollectionsMenu = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const collections = useSelector(selectAllCollections);
  const selectedCollection = useSelector(selectedCollectionSelector);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!collections?.length) {
    return null;
  }

  return (
    <div>
      <Button
        color="inherit"
        startIcon={<CollectionsBookmarkIcon fontSize="small" />}
        onClick={handleClick}
      >
        {selectedCollection.name}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {collections.map(({ id, name }) => (
          <MenuItem
            key={id}
            onClick={() => {
              dispatch(selectedIdChanged(id));
              handleClose();
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CollectionsMenu;
