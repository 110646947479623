import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  actions: {
    display: "flex",
    minWidth: 200,

    "& > * + *": {
      marginLeft: 5,
    },
  },
});

export default useStyles;
