import { useSelector } from "react-redux";
import { selectAllCollections } from "../collectionsSlice";
import { Modal } from "../../../components/basic/Modal/Modal";
import useStyles from "./EditCollectionsModal.style";
import CreateCollectionInput from "./CreateCollectionInput";
import CollectionsList from "./CollectionsList";

const EditCollectionsModal = ({ isOpen, setIsOpen }) => {
  const c = useStyles();

  const collections = useSelector(selectAllCollections);

  const renderTitle = () => (
    <div className={c.header}>
      <div>
        {collections.length}{" "}
        {collections.length > 1 ? "collections" : "collection"}
      </div>
      <CreateCollectionInput />
    </div>
  );

  return (
    <Modal
      fullWidth
      content={<CollectionsList />}
      isOpen={isOpen}
      maxWidth={"md"}
      title={renderTitle()}
      setIsOpen={setIsOpen}
    />
  );
};

export default EditCollectionsModal;
