import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { useEffect, useState } from "react";

export const EditPresetDialog = ({
  open,
  presets,
  name: initialName,
  handleClose,
  handleEditPreset,
  setAlert,
}) => {
  const [name, setName] = useState("");

  useEffect(() => {
    if (open) {
      setName(initialName);
    }
  }, [initialName, open]);

  const handleOk = () => {
    if (!name) {
      setAlert({ type: "error", text: "Please enter preset name" });
      return;
    }

    const hasDuplicates = presets.some(
      ({ name: presetName }) => presetName === name && name !== initialName
    );

    if (hasDuplicates) {
      setAlert({ type: "error", text: `${name} preset already exist` });
      return;
    }

    const hasChanges = name !== initialName;

    if (hasChanges) {
      handleEditPreset(name);
    }

    handleClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        Save Preset as...
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Enter Preset Name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
