import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
}))(MuiDialogActions);

export const Modal = ({
  title,
  content,
  actions,
  isOpen,
  setIsOpen,
  onClose,
  ...restModalProps
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      onClose={handleClose}
      {...restModalProps}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={setIsOpen && handleClose}
      >
        {title}
      </DialogTitle>
      {content && <DialogContent dividers>{content}</DialogContent>}
      {Boolean(actions) && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
