import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { checkStatus } from "../utils/apiCalls";

const INTERVAL_TIMEOUT = 2000;
const MAX_ERROR_ATTEMPTS = 2;

const usePollJob = ({ onFinish, onPoll }) => {
  const [isPolling, setIsPolling] = useState(false);
  const [jobId, setJobId] = useState(null);

  const errorAttempts = useRef(0);

  const stopPolling = (timerId) => {
    clearInterval(timerId);
    setJobId(null);
    setIsPolling(false);
  };

  let source = useRef();

  useEffect(() => {
    let statusCheckInterval;

    if (jobId) {
      setIsPolling(true);
      let CancelToken = axios.CancelToken;
      source.current = CancelToken.source();

      statusCheckInterval = setInterval(
        () =>
          checkStatus(jobId, source.current.token)
            .then(({ data }) => {
              if (data.status === "finished") {
                onFinish().then(() => {
                  stopPolling(statusCheckInterval);
                });
                return;
              }

              onPoll?.(data);
            })
            .catch(() => {
              if (errorAttempts.current < MAX_ERROR_ATTEMPTS) {
                ++errorAttempts.current;
                return;
              }

              stopPolling(statusCheckInterval);
            }),
        INTERVAL_TIMEOUT
      );
    }

    return () => clearInterval(statusCheckInterval);
  }, [jobId]);

  const handleRequest = (callback) => {
    setIsPolling(true);
    callback().then(({ data: { job_status_id } }) => {
      setJobId(job_status_id);
    });
  };

  const cancelPoll = () => {
    source.current?.cancel();
  };

  return { isPolling, cancelPoll, handleRequest, setJobId };
};

export default usePollJob;
