import { configureStore } from "@reduxjs/toolkit";
import collectionsReducer from "./features/collections/collectionsSlice";
import imageTaggingSlice from "./features/imageTagging/imageTaggingSlice";

export default configureStore({
  reducer: {
    collections: collectionsReducer,
    images: imageTaggingSlice,
  },
});
