import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import React, { useCallback, useEffect, useState } from "react";
import { ReviewItem } from "./ReviewItem";
import {
  getFilesList,
  setRate,
  deleteFile,
  deleteRate,
} from "../../../utils/apiCalls";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { selectedCollectionIdSelector } from "../../../features/collections/collectionsSlice";
import useLanguagesList from "../../../hooks/useLanguagesList";

const useStyles = makeStyles(() => ({
  stepHeader: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: "24px",
  },
  reviewItem: {
    marginBottom: "24px",
  },
  loader: {
    margin: "200px auto",
    display: "block",
  },
}));

export const Review = ({ setStep, setAlert }) => {
  const classes = useStyles();
  const [filesList, setFilesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedCollectionId = useSelector(selectedCollectionIdSelector);

  const langList = useLanguagesList()

  useEffect(() => {
    setIsLoading(true);
    getFilesList({ collectionId: selectedCollectionId }).then((res) => {
      setFilesList(res.data);
      setIsLoading(false);
    });
  }, []);

  const handleRate = (boolean, fileId, uuid) => {
    const data = {
      gpt_3_uuid: uuid,
      like: boolean,
    };
    setRate(data, fileId).then(({ data }) => {
      const newArr = filesList.map((item) =>
        item.id === data.id ? data : item
      );
      setFilesList(newArr);
    });
  };

  const handleRateUndo = (reviewId) => {
    deleteRate(reviewId).then(({ data }) => {
      const newArr = filesList.map((item) =>
        item.id === data.id ? data : item
      );
      setFilesList(newArr);
    });
  };

  const handleDeleteFile = useCallback((id) => {
    setIsLoading(true);
    deleteFile(id).then(({ data }) => {
      setFilesList(data);
      setIsLoading(false);
      setAlert({ type: "success", text: "File was successfully deleted" });
    });
  }, []);

  return (
    <>
      <div className={classes.stepHeader}>
        <Button variant="contained" color="primary" onClick={() => setStep(6)}>
          Proceed to Stats
        </Button>
      </div>
      <Divider style={{ margin: "0 -48px 32px" }} />
      {!isLoading ? (
        filesList.map(({ content, preset, languages, id, created_at }) => (
          <React.Fragment key={created_at}>
            <ReviewItem
              content={content}
              preset={preset}
              fileId={id}
              languages={languages}
              langList={langList}
              handleRate={handleRate}
              handleRateUndo={handleRateUndo}
              createdAt={created_at}
              handleDeleteFile={() => handleDeleteFile(id)}
              setFilesList={setFilesList}
              setAlert={setAlert}
            />
            <Divider style={{ margin: "32px -48px 40px" }} />
          </React.Fragment>
        ))
      ) : (
        <CircularProgress size={96} className={classes.loader} />
      )}
    </>
  );
};
