import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",

    "& > * + *": {
      marginLeft: 10,
    },
  },
  input: {
    height: 37,
    minWidth: 250,
  },
  inputError: {
    position: "absolute",
    bottom: -21,
  },
});

export default useStyles;
