import { useDispatch } from "react-redux";
import { Button, TextField } from "@material-ui/core";
import useStyles from "./CreateCollectionInput.style";
import { addCollection } from "../../collectionsSlice";
import useCollectionInput from "../../../../hooks/useCollectionInput";

const INITIAL_INPUT_VALUE = "";

const CreateCollectionInput = () => {
  const c = useStyles();
  const dispatch = useDispatch();

  const {
    inputValue,
    isLoading,
    error,
    handleAction,
    handleInputChange,
    handleInputBlur,
    setInputValue,
  } = useCollectionInput({
    initialValue: INITIAL_INPUT_VALUE,
  });

  const handleCreate = () => {
    handleAction(() =>
      dispatch(addCollection(inputValue))
        .unwrap()
        .then(() => {
          setInputValue(INITIAL_INPUT_VALUE);
        })
    );
  };

  return (
    <div className={c.root}>
      <TextField
        error={Boolean(error)}
        InputProps={{
          className: c.input,
        }}
        FormHelperTextProps={{
          className: c.inputError,
        }}
        helperText={error}
        placeholder={"enter name"}
        value={inputValue}
        variant={"outlined"}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />
      <Button
        disabled={isLoading}
        variant="contained"
        color="primary"
        onClick={handleCreate}
      >
        CREATE NEW COLLECTION
      </Button>
    </div>
  );
};

export default CreateCollectionInput;
