import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAllCollections } from "../features/collections/collectionsSlice";

const useCollectionInput = ({ initialValue }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const collections = useSelector(selectAllCollections);

  const hasChanges = inputValue !== initialValue;

  const handleInputChange = (e) => {
    const value = e.target.value;
    const hasChanges = value !== inputValue;

    if (error && hasChanges) {
      setError(null);
    }

    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    setInputValue((prev) => prev.trim());
  };

  const handleAction = (action) => {
    if (!inputValue) {
      setError("The name is required");
      return;
    }

    const hasDuplicates = collections.some(({ name }) => name === inputValue);

    if (hasDuplicates) {
      setError("Collection already exist");
      return;
    }

    setIsLoading(true);
    return action()
      .catch((errMessage) => {
        setError(errMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    hasChanges,
    inputValue,
    isLoading,
    error,
    handleAction,
    handleInputChange,
    handleInputBlur,
    setInputValue,
  };
};

export default useCollectionInput;
