import { Modal } from "../../basic/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { deleteUser, getUsers } from "../../../utils/apiCalls";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
  },
}));

export const DeleteUserModal = ({ isOpen, setIsOpen, setAlert }) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    getUsers().then((res) => setUsersList(res.data));
  }, []);

  const handleSubmit = async () => {
    if (!selectedUser) {
      setError("Please select user to delete");
      return;
    }

    setIsLoading(true);
    await deleteUser(selectedUser).then(() => {
      setAlert({ type: "success", text: "User deleted" });
      setIsLoading(false);
      setIsOpen(false);
      getUsers().then((res) => setUsersList(res.data));
    });
  };

  useEffect(() => {
    setError("");
  }, [selectedUser]);

  const getModalContent = () => (
    <form>
      <FormControl className={classes.select}>
        <InputLabel htmlFor="email">Email</InputLabel>
        <Select
          id="email"
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          {usersList.map(({ id, email }) => (
            <MenuItem value={id} key={id}>
              {email}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </form>
  );

  const getModalActions = () =>
    !isLoading ? (
      <>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Delete
        </Button>
      </>
    ) : (
      <CircularProgress size={24} />
    );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Delete user"}
      content={getModalContent()}
      actions={getModalActions()}
    />
  );
};
