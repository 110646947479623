import React, { useState } from "react";
import useStyles from "./CollectionsRow.style";
import { Button, TableCell, TableRow, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ACTIONS_COLUMN_ID, NAME_COLUMN_ID } from "../CollectionsList";
import EditableTextField from "../../../../../components/basic/EditableTextField";
import useCollectionInput from "../../../../../hooks/useCollectionInput";
import { deleteCollection, editCollection } from "../../../collectionsSlice";
import { useDispatch } from "react-redux";
import { Modal } from "../../../../../components/basic/Modal/Modal";

const DEFAULT_EDIT_MODE = "view";

const CollectionsRow = ({ row, columns }) => {
  const c = useStyles();
  const dispatch = useDispatch();

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [mode, setMode] = useState(DEFAULT_EDIT_MODE);
  const {
    hasChanges,
    inputValue,
    isLoading,
    error,
    handleAction,
    handleInputChange,
    handleInputBlur,
  } = useCollectionInput({
    initialValue: row[NAME_COLUMN_ID],
  });

  const openConfirmDelete = () => {
    setIsConfirmDeleteOpen(true);
  };

  const closeConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
    dispatch(deleteCollection({ id: row.id }));
  };

  const renderActions = () => {
    const handleEditClick = () => {
      if (mode === "edit") {
        if (!hasChanges) {
          setMode(DEFAULT_EDIT_MODE);
          return;
        }

        handleAction(() =>
          dispatch(editCollection({ id: row.id, name: inputValue }))
            .unwrap()
            .then(() => {
              setMode(mode === "edit" ? DEFAULT_EDIT_MODE : "edit");
            })
        );
      } else {
        setMode("edit");
      }
    };

    return (
      <div className={c.actions}>
        <Button
          color={mode === "edit" ? "secondary" : "primary"}
          disabled={isLoading}
          startIcon={<EditIcon fontSize="small" />}
          onClick={handleEditClick}
        >
          {mode === "edit" ? "SAVE" : "EDIT"}
        </Button>
        <Tooltip
          title={!row.deletable ? "You can't delete default collection" : ""}
        >
          <span>
            <Button
              color={"primary"}
              disabled={!row.deletable}
              startIcon={<DeleteForeverIcon fontSize="small" />}
              onClick={openConfirmDelete}
            >
              DELETE
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <TableRow hover tabIndex={-1}>
      {columns.map((column) => {
        const renderCell = () => {
          switch (column.id) {
            case ACTIONS_COLUMN_ID: {
              return renderActions();
            }
            case NAME_COLUMN_ID: {
              return (
                <EditableTextField
                  autoFocus
                  error={error}
                  helperText={error}
                  mode={mode}
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
              );
            }
            default:
              return row[column.id];
          }
        };

        return (
          <TableCell key={column.id} align={column.align}>
            {renderCell()}
          </TableCell>
        );
      })}
      <Modal
        isOpen={isConfirmDeleteOpen}
        title={"Are you sure you want to delete this collection?"}
        actions={
          <>
            <Button
              color={"secondary"}
              disabled={isLoading}
              onClick={closeConfirmDelete}
            >
              CANCEL
            </Button>
            <Button
              color={"primary"}
              disabled={isLoading}
              onClick={handleConfirmDelete}
            >
              OK
            </Button>
          </>
        }
      />
    </TableRow>
  );
};

export default CollectionsRow;
