import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { LoginModal } from "../../features/Auth/LoginModal";
import { ChangePasswordModal } from "../../features/Auth/ChangePasswordModal";
import { logout } from "../../../utils/apiCalls";
import CollectionsMenu from "./CollectionsMenu";
import { useRouteMatch } from "react-router-dom";
import { IMAGE_UPLOAD_ROUTE } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "14px 48px",
    backgroundColor: "#424450",
    flexDirection: "row",
    alignItems: "center",

    "& > :first-child": {
      marginRight: "auto",
    },

    "& > * + *": {
      marginLeft: 34,
    },
  },
  stepTitle: {
    fontSize: "24px",
    lineHeight: "inherit",
  },
}));

export const Header = ({ setAlert, user, setUser, currentStep }) => {
  const classes = useStyles();
  const isImageUploadPage = useRouteMatch(IMAGE_UPLOAD_ROUTE);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    setIsLoginModalOpen(true);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout().then(() => {
      setUser(null);
      window.localStorage.removeItem("user");
    });
  };

  const handleChangePasswordClick = () => {
    setAnchorEl(null);
    setIsChangePasswordModalOpen(true);
  };

  return (
    <>
      <AppBar position="static" className={classes.header}>
        <Typography variant="h2" className={classes.stepTitle}>
          {isImageUploadPage ? "Image Tagging" : currentStep}
        </Typography>
        {!isImageUploadPage && <CollectionsMenu />}
        <Button
          className={classes.accountButton}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={user ? handleMenu : handleLogin}
          color="inherit"
        >
          {user ? user.email : "Login"}
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleChangePasswordClick}>
            Change password
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </AppBar>
      <LoginModal
        isOpen={isLoginModalOpen}
        setIsOpen={setIsLoginModalOpen}
        setUser={setUser}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        setIsOpen={setIsChangePasswordModalOpen}
        setAlert={setAlert}
      />
    </>
  );
};
