import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: 0,
    lineHeight: 1.43,
  },
  rootDiv: {
    borderBottom: "1px solid transparent",
  },
});

export default useStyles;
