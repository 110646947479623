import React, { useEffect, useState } from "react";
import useStyles from "./ReviewTranslate.style";
import { Button, CircularProgress } from "@material-ui/core";
import { translateFiles } from "../../../../utils/apiCalls";
import cn from "classnames";
import usePollJob from "../../../../hooks/usePollJob";
import ChipsPicker from "../../../basic/ChipsPicker";

const ReviewTranslate = ({ fileId, languages, langList, onSave }) => {
  const c = useStyles();

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const { handleRequest, isPolling } = usePollJob({ onFinish: onSave });

  const hasChanges =
    JSON.stringify(selectedLanguages.sort()) !==
    JSON.stringify(languages.sort());

  useEffect(() => {
    setSelectedLanguages(languages);
  }, []);

  const handleAddLanguage = (id) => {
    setSelectedLanguages([...selectedLanguages, id]);
  };

  const handleDeleteLanguage = (id) => {
    setSelectedLanguages((prev) => {
      return prev.filter((item) => item !== id);
    });
  };

  const handleSave = () => {
    handleRequest(() =>
      translateFiles({ fileId, languages: selectedLanguages })
    );
  };

  if (!langList.length) {
    return null;
  }

  return (
    <div className={c.root}>
      <div className={c.column}>Translate into the following languages:</div>
      <div className={cn(c.column, c.chipsRoot)}>
        <ChipsPicker
          list={langList}
          selectedList={selectedLanguages}
          onAdd={handleAddLanguage}
          onDelete={handleDeleteLanguage}
        />
        <Button
          color={"primary"}
          disabled={!hasChanges || isPolling}
          size={"large"}
          variant={"outlined"}
          onClick={handleSave}
        >
          Run translation
          {isPolling && <CircularProgress className={c.progress} size={20} />}
        </Button>
      </div>
    </div>
  );
};

export default ReviewTranslate;
