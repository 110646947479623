import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(() => ({
  card: {
    padding: "24px",
  },
  cardHeaderWrapper: {
    marginBottom: "24px",
  },
  cardDivider: {
    margin: "0 -24px 24px",
  },
}));

export const CustomCard = ({ header, children }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.cardHeaderWrapper}>{header}</div>
      <Divider className={classes.cardDivider} />
      {children}
    </Card>
  );
};
