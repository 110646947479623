import React, { useEffect } from "react";
import useStyles from "./TaggingProgressBar.style";
import {
  getTaggingPollId,
  setTaggingPollId,
  taggingPollIdSelector,
  taggingPollProgressSelector,
  setTaggingPollProgress,
} from "../imageTaggingSlice";
import { useDispatch, useSelector } from "react-redux";
import usePollJob from "../../../hooks/usePollJob";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const TaggingProgressBar = () => {
  const c = useStyles();
  const dispatch = useDispatch();
  const currentProgress = useSelector(taggingPollProgressSelector);
  const pollId = useSelector(taggingPollIdSelector);

  const handlePoll = (data) => {
    const { progressMax, progressNow } = data;
    const progress = (100 / progressMax) * progressNow;
    dispatch(setTaggingPollProgress(progress));
  };

  const handleFinish = () => {
    return dispatch(getTaggingPollId())
      .unwrap()
      .then(() => {
        dispatch(setTaggingPollId(null));
      });
  };

  const { setJobId, cancelPoll } = usePollJob({
    onFinish: handleFinish,
    onPoll: handlePoll,
  });

  const handleCancel = () => {
    cancelPoll();
    dispatch(setTaggingPollProgress(null));
  };

  const startPollingTagging = () => {
    if (!pollId) {
      return;
    }

    setJobId(pollId.job_status_id);
  };

  const getPollIdOnMount = () => {
    dispatch(getTaggingPollId());
  };

  useEffect(getPollIdOnMount, [dispatch]);
  useEffect(startPollingTagging, [pollId]);

  if (!pollId) {
    return null;
  }

  return (
    <Box className={c.root}>
      <div className={c.progressRoot}>
        <CircularProgress
          className={c.circle}
          variant="determinate"
          value={currentProgress}
          size="96px"
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            className={c.text}
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(currentProgress || 0)}%`}</Typography>
        </Box>
        {/* <Button
          className={c.button}
          color="primary"
          variant="contained"
          onClick={handleCancel}
        >
          Cancel request
        </Button> */}
      </div>
    </Box>
  );
};

export default TaggingProgressBar;
