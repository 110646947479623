import React from "react";
import useStyles from "./ChipsPicker.style";
import { Chip } from "@material-ui/core";

const ChipsPicker = ({ list, selectedList, onAdd, onDelete }) => {
  const c = useStyles();

  return (
    <div className={c.root}>
      {list?.map(({ name, id }) => {
        const isSelected = selectedList?.includes(id);

        return (
          <Chip
            color={isSelected ? "primary" : "default"}
            label={name}
            key={id}
            onClick={() => onAdd(id)}
            onDelete={isSelected ? () => onDelete(id) : undefined}
          />
        );
      })}
    </div>
  );
};

export default ChipsPicker;
