import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: 24,
    paddingBottom: 96,
    width: "100%",

    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px!important",
    },

    "& .MuiButton-root": {
      textTransform: "uppercase!important",
    },
  },
  margins: {
    margin: "24px 0",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    fontWeight: "bold",
    padding: "15px",
    color: "black",
  },
  valueLabel: {
    top: 30,
    color: "transparent",
    "& > * > *": {
      background: "none",
      color: "black",
    },
  },
  select: {
    flex: 1,

    "& > :first-child": {
      marginBottom: 7,
      textAlign: "left",
    },
  },
  watermarkRow: {
    minHeight: 88,
  },
});

export default useStyles;
