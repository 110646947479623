import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  controls: {
    display: "flex",

    "& > * + *": {
      marginLeft: 10,
    },
  },
  button: {
    padding: "5px 30px",
    whiteSpace: "nowrap",
  },
});

export default useStyles;
