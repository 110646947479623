import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > :last-child": {
      marginRight: 40,
    },
  },
});

export default useStyles;
