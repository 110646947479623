export const checkAttributesMatch = (prompt, attributes) => {
  const presetAttributes = prompt.match(/@\([A-z]+\)/g);

  if (!presetAttributes) return true;

  const formattedAttributes = attributes.map((attribute) => `@(${attribute})`);
  return presetAttributes.every((attribute) =>
    formattedAttributes.includes(attribute)
  );
};
