import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { CreatePresetDialog } from "./CreatePresetDialog";
import { DeletePresetDialog } from "./DeletePresetDialog";
import { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  getPresetsRequest,
  createPresetRequest,
  deletePresetRequest,
  getPresetSettingsRequest,
} from "../../../utils/apiCalls";
import { checkAttributesMatch } from "../../../utils/checkAttributesMatch";
import { responseSettingsNormalizer } from "../../../utils/settingsNormalizer";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { IS_NLG } from "../../../constants";
import { useSelector } from "react-redux";
import { selectedCollectionIdSelector } from "../../../features/collections/collectionsSlice";
import { EditPresetDialog } from "./EditPresetDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  selectWrapper: {
    minWidth: "300px",
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "100%",
    marginLeft: "10px",
  },
  actionButton: {
    marginLeft: "16px",
  },
}));

export const PresetsSelector = ({
  allowActions,
  attributes,
  isAdmin,
  requestSettings,
  selectedPreset: selectedPresetId,
  handleEditName,
  handleSavePreset,
  setAlert,
  setAttributes,
  setRequestSettings,
  setSelectedPreset,
}) => {
  const classes = useStyles();
  const [isCreateNewDialogOpen, setIsCreateNewDiaologOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [presets, setPresets] = useState([]);
  const [isPresetsForLanding, setIsPresetsForLanding] = useState(false);
  const cashedAtrributes = useRef([]);

  const selectedCollectionId = useSelector(selectedCollectionIdSelector);

  const selectedPreset = presets.find(({ id }) => id === selectedPresetId);

  useEffect(() => {
    if (selectedPresetId && allowActions) {
      handlePresetSelect(selectedPresetId);
    }
  }, []);

  useEffect(() => {
    getPresetsList();
    if (isPresetsForLanding) {
      cashedAtrributes.current = attributes;
      setAttributes(["About"]);
    } else {
      restoreCashedAttributes();
    }

    return () => restoreCashedAttributes();
  }, [isPresetsForLanding]);

  const restoreCashedAttributes = () => {
    !!cashedAtrributes.current.length &&
      setAttributes(cashedAtrributes.current);
  };

  const handlePresetSelect = (id) => {
    getPresetSettingsRequest({
      id,
      collectionId: selectedCollectionId,
    }).then(({ data }) => {
      if (!allowActions) {
        if (checkAttributesMatch(data.prompt, attributes)) {
          setSelectedPreset(id);
        } else {
          setAlert({ type: "error", text: "This preset doesn't fit to JSON" });
        }
      } else {
        setSelectedPreset(id);
        setRequestSettings(responseSettingsNormalizer(data));
      }
    });
  };

  const getPresetsList = () => {
    getPresetsRequest({
      isPresetsForLanding,
      collectionId: selectedCollectionId,
    }).then(({ data }) => setPresets(data));
  };

  const handleCreateNewClick = () => {
    if (!requestSettings.prompt) {
      setAlert({ type: "error", text: "Please provide GPT-3 prompt" });
    } else {
      setIsCreateNewDiaologOpen(true);
    }
  };

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const handleCreatePreset = (name) => {
    createPresetRequest({
      data: { ...requestSettings, name },
      id: isPresetsForLanding,
      collectionId: selectedCollectionId,
    })
      .then(({ data }) => {
        getPresetsList();
        setAlert({ type: "success", text: `Preset ${name} created` });
        setSelectedPreset(data.id);
        setRequestSettings(responseSettingsNormalizer(data));
      })
      .catch((err) => {
        if (err.response.data.errors.name) {
          setAlert({ type: "error", text: "The name has already been taken" });
          return;
        }
        setAlert({ type: "error", text: "Error" });
      });
  };

  const handleDeletePreset = () => {
    deletePresetRequest(selectedPresetId).then(() => {
      getPresetsList();
      setAlert({ type: "success", text: "Preset was successfully deleted" });
      setSelectedPreset("");
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.selectWrapper}>
        <InputLabel htmlFor="preset-select" style={{ pointerEvents: "none" }}>
          Preset:
        </InputLabel>
        <Select
          displayEmpty
          id="preset-select"
          className={classes.select}
          onChange={(e) => handlePresetSelect(e.target.value)}
          value={selectedPresetId}
          input={<OutlinedInput />}
          style={{ maxWidth: "240px", paddingRight: "5px" }}
        >
          {presets.map(({ name, id }) => (
            <MenuItem value={id} key={id}>
              {name} (id: {id})
            </MenuItem>
          ))}
        </Select>
      </div>

      {allowActions && (
        <div>
          <Button
            className={classes.actionButton}
            color="primary"
            disabled={!selectedPresetId}
            startIcon={<EditIcon />}
            variant="outlined"
            onClick={handleEditClick}
          >
            Edit name
          </Button>
          <Button
            className={classes.actionButton}
            variant="outlined"
            color="primary"
            onClick={() => handleSavePreset()}
            startIcon={<SaveIcon />}
            disabled={!selectedPresetId}
          >
            Save
          </Button>
          <Button
            className={classes.actionButton}
            onClick={handleCreateNewClick}
            variant="outlined"
            color="primary"
            startIcon={<CreateNewFolderIcon />}
          >
            Create New
          </Button>
          <Button
            className={classes.actionButton}
            onClick={() => setIsDeleteDialogOpen(true)}
            variant="outlined"
            color="secondary"
            disabled={!selectedPresetId}
            startIcon={<DeleteForeverIcon />}
          >
            Delete
          </Button>
        </div>
      )}
      <CreatePresetDialog
        open={isCreateNewDialogOpen}
        handleClose={() => setIsCreateNewDiaologOpen(false)}
        handleCreatePreset={handleCreatePreset}
        setAlert={setAlert}
      />
      <EditPresetDialog
        name={selectedPreset?.name}
        open={isEditDialogOpen}
        presets={presets}
        handleClose={() => setIsEditDialogOpen(false)}
        handleEditPreset={(name) =>
          handleEditName(name).finally(getPresetsList)
        }
        setAlert={setAlert}
      />
      <DeletePresetDialog
        open={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleDeletePreset={handleDeletePreset}
      />
      {IS_NLG && isAdmin && (
        <FormControlLabel
          value="start"
          control={
            <Checkbox
              color="primary"
              checked={isPresetsForLanding}
              onChange={(e) => setIsPresetsForLanding(e.target.checked)}
            />
          }
          label="Presets for landing"
          labelPlacement="start"
        />
      )}
    </div>
  );
};
