import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "./components/basic/Header/Header";
import { SidePanel } from "./components/basic/SidePanel/SidePanel";
import { ReviewJson } from "./components/features/ReviewJson/ReviewJson";
import { RequestEditor } from "./components/features/RequestEditor/RequestEditor";
import { Preview } from "./components/features/Preview/Preview";
import { Review } from "./components/features/Review/Review";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Generate } from "./components/features/Generate/Generate";
import { Stats } from "./components/features/Stats/Stats";
import {
  fetchCollections,
  selectedCollectionIdSelector,
} from "./features/collections/collectionsSlice";
import "./styles/styles.scss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IMAGE_UPLOAD_ROUTE } from "./constants";
import ImageTagging from "./features/imageTagging/ImageTagging";

function getStepsList() {
  return [
    "Review JSON Input File",
    "Editor",
    "Preview",
    "Generate",
    "Review",
    "Stats",
  ];
}

const INITIAL_JSON = "[{}]";
const INITIAL_ATTRIBUTES = [];
const INITIAL_STEP = 1;
const STEP_TO_DISABLE_STEPPER = -1;
const INITIAL_PRESET = "";

const App = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const isImageUploadPage = useRouteMatch(IMAGE_UPLOAD_ROUTE);

  const [alert, setAlert] = useState(null);

  const [json, setJson] = useState(INITIAL_JSON);
  const [attributes, setAttributes] = useState(INITIAL_ATTRIBUTES);
  const [step, setStep] = useState(INITIAL_STEP);
  const [selectedPreset, setSelectedPreset] = useState(INITIAL_PRESET);

  const initUser = window.localStorage.getItem("user");
  const [user, setUser] = useState(initUser ? JSON.parse(initUser) : null);

  const stepsList = getStepsList();
  const isAdmin = user?.role === "admin";

  const selectedCollectionId = useSelector(selectedCollectionIdSelector);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const setStepWithJsonCheck = (stepNumber) => {
    if (isImageUploadPage) {
      history.push("/");
    }

    try {
      JSON.parse(json);
      setStep(stepNumber);
    } catch (e) {
      setStep(INITIAL_STEP);
      setAlert({ type: "error", text: "Invalid JSON format" });
    }
  };

  useEffect(() => {
    user && dispatch(fetchCollections());
  }, [user, dispatch]);

  useEffect(() => {
    if (isImageUploadPage) {
      setStep(STEP_TO_DISABLE_STEPPER);
    }
  }, [isImageUploadPage]);

  const resetDefaultsOnCollectionChange = () => () => {
    setStep(INITIAL_STEP);
    setAttributes(INITIAL_ATTRIBUTES);
    setJson(INITIAL_JSON);
    setSelectedPreset(INITIAL_PRESET);
  };

  useEffect(resetDefaultsOnCollectionChange, [selectedCollectionId]);

  const steps = {
    1: (
      <ReviewJson
        json={json}
        setJson={setJson}
        attributes={attributes}
        setAttributes={setAttributes}
        setStep={setStepWithJsonCheck}
        setAlert={setAlert}
        setSelectedPreset={setSelectedPreset}
      />
    ),
    2: (
      <RequestEditor
        attributes={attributes}
        setAlert={setAlert}
        setSelectedPreset={setSelectedPreset}
        selectedPreset={selectedPreset}
        setStep={setStep}
        isAdmin={isAdmin}
        setAttributes={setAttributes}
      />
    ),
    3: (
      <Preview
        json={json}
        attributes={attributes}
        setAlert={setAlert}
        setSelectedPreset={setSelectedPreset}
        selectedPreset={selectedPreset}
        setStep={setStep}
      />
    ),
    4: (
      <Generate
        json={json}
        setAlert={setAlert}
        attributes={attributes}
        setSelectedPreset={setSelectedPreset}
        selectedPreset={selectedPreset}
        setStep={setStep}
      />
    ),
    5: <Review setStep={setStep} setAlert={setAlert} />,
    6: <Stats />,
  };

  const getLoginMessage = () => {
    return <h1>Please Login to use application</h1>;
  };

  const renderContent = () => {
    if (!user) {
      return getLoginMessage();
    }

    return isImageUploadPage ? <ImageTagging /> : steps[step];
  };

  return (
    <>
      <SidePanel
        step={step}
        setStep={setStepWithJsonCheck}
        setAlert={setAlert}
        isAdmin={isAdmin}
        stepsList={stepsList}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          paddingLeft: "240px",
        }}
      >
        <Header
          alert={alert}
          setAlert={setAlert}
          user={user}
          setUser={setUser}
          currentStep={stepsList[step - 1]}
        />
        <main
          style={{
            flex: 1,
            padding: "24px 48px",
            backgroundColor: "#FAFAFA",
          }}
        >
          {renderContent()}
        </main>
        <Snackbar
          open={!!alert}
          onClose={() => setAlert(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={() => setAlert(null)} severity={alert?.type}>
            {alert?.text}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default App;
