import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",

    "& > * + *": {
      marginLeft: 15,
    },
  },
});

export default useStyles;
