import { useEffect, useState } from "react";
import { getPresetsChartsData } from "../../../utils/apiCalls";
import { Bar } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import { selectedCollectionIdSelector } from "../../../features/collections/collectionsSlice";

export const Stats = () => {
  const [chartsData, setChartsData] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [statistics, setStatistics] = useState(null);

  const selectedCollectionId = useSelector(selectedCollectionIdSelector);

  useEffect(() => {
    getPresetsChartsData({ collectionId: selectedCollectionId }).then(
      ({ data }) => {
        setChartsData(data.ratings.items);
        setTotalCount(data.ratings.total);
        setStatistics(data.statistics);
      }
    );
  }, []);

  const barData = {
    labels: chartsData.map(
      ({ name, dislikes_count, likes_count }) =>
        `${name} - ${dislikes_count + likes_count} rates`
    ),
    datasets: [
      {
        label: "# of dislikes",
        data: chartsData.map(({ dislikes_count }) => dislikes_count),
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "# of likes",
        data: chartsData.map(({ likes_count }) => likes_count),
        backgroundColor: "rgb(54, 162, 235)",
      },
    ],
  };

  return (
    <>
      {totalCount && (
        <Typography>There are {totalCount} ratings in total</Typography>
      )}
      <Bar data={barData} />

      <TableContainer component={Paper} style={{ marginTop: "50px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">year</TableCell>
              <TableCell align="center">month</TableCell>
              <TableCell align="center">gpt-3</TableCell>
              <TableCell align="center">images</TableCell>
              <TableCell align="center">translate</TableCell>
              <TableCell align="center">total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statistics?.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{row.year}</TableCell>
                <TableCell align="center">{row.month}</TableCell>
                <TableCell align="center">{row.gpt3_count}</TableCell>
                <TableCell align="center">{row.images_count}</TableCell>
                <TableCell align="center">{row.deepl_count}</TableCell>
                <TableCell align="center">
                  {row.deepl_count + row.gpt3_count}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
