import { Modal } from "../../basic/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createToken, login } from "../../../utils/apiCalls";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  input: {
    width: "300px",
    marginBottom: "24px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const LoginModal = ({ isOpen, setIsOpen, setUser }) => {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email || !password) {
      setError("Please enter your credentials");
      return;
    }

    const userData = {
      email,
      password,
    };

    setIsLoading(true);
    await createToken();
    await login(userData)
      .then((res) => {
        setIsOpen(false);
        setUser(res.data);
        window.localStorage.setItem("user", JSON.stringify(res.data));
        setEmail("");
        setPassword("");
      })
      .catch((err) => {
        setError("Wrong credentials")
      });
    setIsLoading(false);
  };

  useEffect(() => {
    setError("");
  }, [email, password]);

  const getModalContent = () => (
    <form className={classes.form}>
      <FormControl className={classes.input}>
        <InputLabel htmlFor="email">Email</InputLabel>
        <Input
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
      <FormControl className={classes.input}>
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          error={!!error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </form>
  );

  const getModalActions = () =>
    !isLoading ? (
      <>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Login
        </Button>
      </>
    ) : (
      <CircularProgress size={24} />
    );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Login"}
      content={getModalContent()}
      actions={getModalActions()}
    />
  );
};
