import { Modal } from "../../basic/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { changePassword } from "../../../utils/apiCalls";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    width: "100%",
    marginBottom: "24px",
    maxWidth: "300px",
  },
}));

export const ChangePasswordModal = ({ isOpen, setIsOpen, setAlert }) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPasswords, setShowPasswords] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError("");
    setOldPasswordError("");
  }, [oldPassword, newPassword, repeatPassword]);

  useEffect(() => {
    setOldPassword("");
    setNewPassword("");
    setRepeatPassword("");
    setShowPasswords(false);
  }, [isOpen]);

  const handleSubmit = async () => {
    if (!oldPassword || !newPassword || !repeatPassword) {
      setError("Please fill all fields");
      return;
    }

    setIsLoading(true);
    const reqData = {
      password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: repeatPassword,
    };
    await changePassword(reqData).then(() => {
      setIsLoading(false);
      setIsOpen(false);
      setAlert({ type: "success", text: "Password changed" });
    });
  };

  const getModalContent = () => (
    <form className={classes.form}>
      <FormControl className={classes.input}>
        <InputLabel htmlFor="old-password">Old password</InputLabel>
        <Input
          id="old-password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type={showPasswords ? "text" : "password"}
          error={!!error || !!oldPasswordError}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPasswords(!showPasswords)}
                edge="end"
              >
                {showPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {(error || oldPasswordError) && (
          <FormHelperText error>{error || oldPasswordError}</FormHelperText>
        )}
      </FormControl>
      <FormControl className={classes.input}>
        <InputLabel htmlFor="new-password">New password</InputLabel>
        <Input
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type={showPasswords ? "text" : "password"}
          error={!!error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPasswords(!showPasswords)}
                edge="end"
              >
                {showPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
      <FormControl className={classes.input}>
        <InputLabel htmlFor="repeat-password">Repeat password</InputLabel>
        <Input
          id="repeat-password"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          type={showPasswords ? "text" : "password"}
          error={!!error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPasswords(!showPasswords)}
                edge="end"
              >
                {showPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </form>
  );

  const getModalActions = () =>
    !isLoading ? (
      <>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </>
    ) : (
      <CircularProgress size={24} />
    );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Change password"}
      content={getModalContent()}
      actions={getModalActions()}
    />
  );
};
