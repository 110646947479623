import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useCallback, useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { PresetsSelector } from "../../basic/PresetsSelector/PresetsSelector";
import ReactJson from "react-json-view";
import { getPreview } from "../../../utils/apiCalls";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomCard } from "../../basic/CustomCard/CustomCard";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  stepHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  },
  jsonContainer: {
    minWidth: "650px",
    maxWidth: '30%'
  },
  cardTitle: {
    marginRight: "auto",
    lineHeight: "48px",
  },
  jsonActions: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  loader: {
    display: "block",
    margin: "0 auto",
  },
  actionButton: {
    border: "1px solid",
    marginLeft: "12px",
  },
  previewContainer: {
    width: '100%'
  }
}));

export const Preview = ({
  json,
  setSelectedPreset,
  selectedPreset,
  setStep,
  setAlert,
  attributes,
}) => {
  const classes = useStyles();
  const [jsonIndex, setJsonIndex] = useState(0);
  const [jsonItem, setJsonItem] = useState(null);
  const [preview, setPreview] = useState("");
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const jsonLength = JSON.parse(json).length;

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowLeft" && jsonIndex !== 0) {
        setJsonIndex((i) => i - 1);
      }

      if (e.key === "ArrowRight" && jsonIndex !== jsonLength - 1) {
        setJsonIndex((i) => i + 1);
      }
    },
    [jsonIndex, jsonLength]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const parsedJson = JSON.parse(json);
    const jsonItem = parsedJson[jsonIndex];
    setJsonItem(jsonItem);
  }, [json, jsonIndex]);

  useEffect(() => {
    if (!selectedPreset) {
      setAlert({ type: "error", text: "Please select preset to see preview" });
      return;
    }
    if (jsonItem) {
      const requestData = {
        product: jsonItem,
        preset_id: selectedPreset,
      };
      setIsPreviewLoading(true);

      getPreview(requestData).then((res) => {
        setIsPreviewLoading(false);
        setPreview(res.data);
      });
    }
  }, [selectedPreset, jsonItem]);

  return (
    <>
      <div className={classes.stepHeader}>
        <PresetsSelector
          setAlert={setAlert}
          setSelectedPreset={setSelectedPreset}
          selectedPreset={selectedPreset}
          allowActions={false}
          attributes={attributes}
        />
        <Button variant="contained" color="primary" onClick={() => setStep(4)}>
          Proceed to Generate
        </Button>
      </div>
      <Divider style={{ margin: "0 -48px 32px" }} />
      <Grid container alignItems="stretch" spacing={8} wrap="nowrap">
        <Grid item className={classes.jsonContainer}>
          <CustomCard
            header={
              <div className={classes.jsonActions}>
                <Typography className={classes.cardTitle} variant="h6">
                  Selected JSON Item - {jsonIndex + 1}
                </Typography>
                <IconButton
                  className={classes.actionButton}
                  color="primary"
                  disabled={jsonIndex === 0}
                  onClick={() => setJsonIndex((i) => i - 1)}
                >
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  className={classes.actionButton}
                  color="primary"
                  disabled={jsonIndex === jsonLength - 1}
                  onClick={() => setJsonIndex((i) => i + 1)}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </div>
            }
          >
            {jsonItem && (
              <ReactJson
                src={jsonItem}
                name={false}
                indentWidth={4}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            )}
          </CustomCard>
        </Grid>
        <Grid item className={classes.previewContainer}>
          <CustomCard
            header={
              <Typography className={classes.cardTitle} variant="h6">
                Preview of generated text for JSON Item - {jsonIndex + 1}
              </Typography>
            }
          >
            {isPreviewLoading ? (
              <CircularProgress size={48} className={classes.loader} />
            ) : (
              preview
            )}
          </CustomCard>
        </Grid>
      </Grid>
    </>
  );
};
