import React, { useEffect, useRef } from "react";
import useStyles from "./CollectionsList.style";
import { useSelector } from "react-redux";
import {
  DEFAULT_COLLECTION_ID,
  selectAllCollections,
} from "../../collectionsSlice";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CollectionsRow from "./CollectionsRow";

export const ACTIONS_COLUMN_ID = "actions";
export const NAME_COLUMN_ID = "name";

const CollectionsList = () => {
  const c = useStyles();

  const currentCollectionsLength = useRef(0);
  const tableElement = useRef(null);

  const collections = useSelector(selectAllCollections);

  const scrollNewCellIntoView = () => {
    const isMount = !currentCollectionsLength.current;

    if (isMount) {
      currentCollectionsLength.current = collections.length;
      return;
    }

    const hasNewElement = collections.length > currentCollectionsLength.current;

    currentCollectionsLength.current = collections.length;

    if (tableElement.current && hasNewElement) {
      tableElement.current.scrollTo({
        top: tableElement.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(scrollNewCellIntoView, [collections.length]);

  const columns = [
    { id: "id", label: "ID", align: "left", maxWidth: 130 },
    { id: NAME_COLUMN_ID, label: "Name", align: "left", maxWidth: 300 },
    { id: ACTIONS_COLUMN_ID, label: "Actions", align: "left" },
  ];

  const rows = collections.map((collection) => ({
    ...collection,
    deletable: collection.id !== DEFAULT_COLLECTION_ID,
  }));

  return (
    <Paper elevation={0} className={c.root}>
      <TableContainer className={c.container} ref={tableElement}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(({ id, align, label, maxWidth }) => (
                <TableCell
                  key={id}
                  align={align}
                  className={c.headerCell}
                  width={maxWidth}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <CollectionsRow key={row.id} row={row} columns={columns} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CollectionsList;
