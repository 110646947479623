import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useEffect, useRef, useState } from "react";
import {
  saveJsonToDatabase,
  getJsonFromDatabase,
} from "../../../utils/apiCalls";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomCard } from "../../basic/CustomCard/CustomCard";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import {
  isCollectionsLoadingSelector,
  selectedCollectionIdSelector,
} from "../../../features/collections/collectionsSlice";

const useStyles = makeStyles((theme) => ({
  stepHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "24px",
  },
  jsonInput: {
    minHeight: "600px",
    width: "400px",
    borderColor: "#999999",
    borderRadius: "4px",
  },
  arrowIcon: {
    marginTop: "250px",
  },
  attributesContainer: {
    minHeight: "600px",
    minWidth: "200px",
  },
  formTitle: {
    fontSize: "18px",
    marginBottom: "16px",
  },
  attribute: {
    margin: "0 auto",
    padding: "8px 16px",
    textTransform: "capitalize",
    backgroundColor: "#3f51b5",
    color: "#fff",
  },
  bigLoader: {
    display: "block",
    margin: "200px auto",
  },
  attributeBadge: {
    marginBottom: "16px",
    marginRight: "16px",
  },
}));

export const ReviewJson = ({
  json,
  setJson,
  attributes,
  setAttributes,
  setStep,
  setAlert,
  setSelectedPreset,
}) => {
  const classes = useStyles();
  const jsonInput = useRef();
  const [isJsonLoading, setIsJsonLoading] = useState(true);
  const [isJsonSaving, setIsJsonSaving] = useState(false);

  const isCollectionsLoading = useSelector(isCollectionsLoadingSelector);
  const selectedCollectionId = useSelector(selectedCollectionIdSelector);

  const onJsonInputChange = (value) => {
    setAttributes([]);

    if (!value) return;

    let parsedValue;

    try {
      parsedValue = JSON.parse(value);
    } catch (err) {
      setAlert({ type: "error", text: "Invalid JSON format" });
      return;
    }

    if (!Array.isArray(parsedValue)) {
      setAlert({ type: "error", text: "Invalid JSON format" });
      return;
    }

    const product = parsedValue[0];

    if (typeof product !== "object") {
      setAlert({ type: "error", text: "Invalid JSON format" });
      return;
    }

    const productFields = Object.keys(product);
    setAttributes(productFields);
  };

  useEffect(() => {
    onJsonInputChange(json);
  }, [json]);

  useEffect(() => {
    setSelectedPreset("");
  }, []);

  useEffect(() => {
    if (!isCollectionsLoading) {
      setIsJsonLoading(true);
      getJsonFromDatabase(selectedCollectionId).then((res) => {
        setJson(res.data);
        setIsJsonLoading(false);
      });
    }
  }, [isCollectionsLoading, selectedCollectionId]);

  const handleProceedClick = () => {
    setIsJsonSaving(true);
    saveJsonToDatabase({ json, collectionId: selectedCollectionId }).then(
      () => {
        setIsJsonSaving(true);
        setStep(2);
      }
    );
  };

  return (
    <>
      <div className={classes.stepHeader}>
        {!isJsonSaving ? (
          <Button
            variant="contained"
            color="primary"
            disabled={!attributes.length}
            onClick={handleProceedClick}
          >
            Proceed to Editor
          </Button>
        ) : (
          <CircularProgress size={24} />
        )}
      </div>
      <Divider style={{ margin: "0 -48px 32px" }} />
      {!isJsonLoading && !isCollectionsLoading ? (
        <Grid
          container
          wrap="nowrap"
          spacing={8}
          justifyContent="space-between"
        >
          <Grid item>
            <CustomCard
              header={
                <Typography className={classes.formTitle} variant="h6">
                  Raw JSON file
                </Typography>
              }
            >
              <TextareaAutosize
                className={classes.jsonInput}
                label="Raw JSON file"
                ref={jsonInput}
                onChange={(e) => setJson(e.target.value)}
                value={json}
              />
            </CustomCard>
          </Grid>
          {!!attributes.length && (
            <Grid item>
              <CustomCard
                header={
                  <Typography className={classes.formTitle} variant="h6">
                    Available JSON attributes
                  </Typography>
                }
              >
                {attributes.map((attribute) => (
                  <Chip
                    label={attribute}
                    className={classes.attributeBadge}
                    key={attribute}
                  />
                ))}
              </CustomCard>
            </Grid>
          )}
        </Grid>
      ) : (
        <CircularProgress size={100} className={classes.bigLoader} />
      )}
    </>
  );
};
