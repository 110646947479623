import { makeStyles } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputLabel from "@material-ui/core/InputLabel";
import { RequestEngineTooltip } from "./RequestEngineTooltip";
import { useEffect, useState } from "react";
import { getEngines } from "../../../utils/apiCalls";

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: "24px",
	},
	select: {
		width: "100%",
	},
	label: {
		fontSize: "14px",
		color: "black",
	},
}));

export const RequestEngineSelect = ({ value, setRequestSettings }) => {
	const classes = useStyles();
	const [tooltip, setTooltip] = useState("");
	const [engines, setEngines] = useState({});

	useEffect(() => {
		getEngines().then(({ data }) => setEngines(data));
	}, []);

	const handleSelect = (e) => {
		e.stopPropagation();
		setRequestSettings((settings) => ({ ...settings, engine: e.target.value }));
	};

	return (
		<div className={classes.root}>
			<RequestEngineTooltip tooltipContent={tooltip} open={!!tooltip}>
				<InputLabel
					htmlFor="engine-select"
					style={{ pointerEvents: "none" }}
					className={classes.label}
				>
					Engine
				</InputLabel>
			</RequestEngineTooltip>

			<Select
				id="engine-select"
				className={classes.select}
				onChange={handleSelect}
				value={value}
				onClose={() => setTooltip(null)}
			>
				<ListSubheader style={{ pointerEvents: "none" }}>Base</ListSubheader>
				{engines.base?.map((engine) => (
					<MenuItem
						value={engine.name}
						onMouseEnter={() => setTooltip(engine)}
						onMouseLeave={() => setTooltip(null)}
						key={engine.name}
					>
						{engine.name}
					</MenuItem>
				))}

				<ListSubheader style={{ pointerEvents: "none" }}>Instruct</ListSubheader>
				{engines.instruct?.map((engine) => (
					<MenuItem
						value={engine.name}
						onMouseEnter={() => setTooltip(engine)}
						onMouseLeave={() => setTooltip(null)}
						key={engine.name}
					>
						{engine.name}
					</MenuItem>
				))}
			</Select>
		</div>
	);
};
