import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../../utils/request";

const INITIAL_SETTINGS = {
  height: null,
  is_min_height: false,
  width: null,
  is_min_width: false,
  is_enlarge: false,
  resize: "auto",
  gravity: "no",
  extension: "jpg",
  position: "ce",
  opacity: 1,
  scale: 1,
  is_watermark: false,
  filters: [],
  languages: [],
  url: "",
};

const initialState = {
  fileId: "",
  entities: [],
  loading: true,
  previewImage: "",
  settings: INITIAL_SETTINGS,
  taggingPollId: null,
  taggingPollProgress: null,
};

export const uploadImages = createAsyncThunk(
  "imageTagging/upload",
  async (files) => {
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("images[]", files[i]);
    }

    const response = await request({
      method: "POST",
      url: "/image-tagging/upload",
      data: formData,
    });
    return response.data;
  }
);

export const getSettings = createAsyncThunk(
  "imageTagging/getSettings",
  async () => {
    const response = await request({
      method: "GET",
      url: "/image-tagging/preset",
    });

    return response.data;
  }
);
export const getUploadedImages = createAsyncThunk(
  "imageTagging/getImages",
  async (_, { getState }) => {
    const response = await request({
      method: "GET",
      url: "/image-tagging/images",
    });

    return response.data;
  }
);

export const clearAllImages = createAsyncThunk(
  "imageTagging/clear",
  async (_, { dispatch }) => {
    try {
      const response = await request({
        method: "POST",
        url: "/image-tagging/clear",
      });

      return response.data;
    } catch (e) {
      dispatch(getUploadedImages());
    }
  }
);

export const startTagging = createAsyncThunk(
  "imageTagging/startTagging",
  async (_, { getState, dispatch }) => {
    try {
      const { selectedLanguages, settings } = getState().images;

      const response = await request({
        method: "POST",
        url: "/image-tagging/generate",
        data: JSON.stringify({ ...settings, ...selectedLanguages }),
      });

      dispatch(setTaggingPollId(response.data));
    } catch (e) {
      dispatch(getUploadedImages());
    }
  }
);

export const getTaggingPollId = createAsyncThunk(
  "imageTagging/getTaggingPollId",
  async (_, {dispatch}) => {
    try {
      const response = await request({
        method: "GET",
        url: "/image-tagging/zip",
      });

      dispatch(setFileId(response.data));
      return response.data;
    } catch (e) {}
  }
);

const imageTaggingSlice = createSlice({
  name: "imageTagging",
  initialState,
  reducers: {
    addSelectedLanguages: (state, action) => {
      state.settings.languages.push(action.payload);
    },
    deleteSelectedLanguage: (state, action) => {
      state.settings.languages = state.settings.languages.filter(
        (item) => item !== action.payload
      );
    },
    setImageSetting: (state, action) => {
      const { name, value } = action.payload;

      state.settings[name] = value;
    },
    setTaggingPollId: (state, action) => {
      state.taggingPollId = action.payload;
    },
    setFileId: (state, action) => {
      state.fileId = action.payload;
    },
    setTaggingPollProgress: (state, action) => {
      state.taggingPollProgress = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        const settings = { ...action.payload };

        state.loading = false;
        state.settings = settings;
      })
      .addCase(getSettings.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(uploadImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadImages.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(uploadImages.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getUploadedImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUploadedImages.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getUploadedImages.rejected, (state) => {
        state.loading = false;
      });

    builder.addCase(clearAllImages.pending, (state) => {
      state.entities = [];
      state.settings = INITIAL_SETTINGS;
    });
  },
});

export const isLoadingImagesSelector = (state) => state.images.loading;
export const imagesSelector = (state) => state.images.entities;
export const imageSettingsSelector = (state) => state.images.settings;
export const taggingPollIdSelector = (state) => state.images.taggingPollId;
export const fileIdSelector = (state) => state.images.fileId;
export const taggingPollProgressSelector = (state) => state.images.taggingPollProgress;

export const {
  addSelectedLanguages,
  deleteSelectedLanguage,
  setImageSetting,
  setTaggingPollId,
  setFileId,
  setTaggingPollProgress,
} = imageTaggingSlice.actions;

export default imageTaggingSlice.reducer;
