export const requestSettingsNormalizer = (data) => {
  const {
    engine,
    responseLength,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    bestOf,
    stopSequences,
    logitBias,
    n,
    name,
    prompt,
    isTranslateInput,
    isTranslateOutput,
    translateInputId,
    translateOutputId,
  } = data;

  const formattedStopSequences = stopSequences.map((item) => {
    return item.replace(/↵/g, "\\n");
  });

  return {
    name,
    prompt,
    engine,
    temperature,
    max_tokens: responseLength,
    top_p: topP,
    frequency_penalty: frequencyPenalty,
    presence_penalty: presencePenalty,
    best_of: bestOf,
    n,
    logit_bias_hr: logitBias.length ? logitBias : null,
    stop: stopSequences.length ? stopSequences : null,
    is_translate_input: isTranslateInput,
    is_translate_output: isTranslateOutput,
    target_output_lang_id: translateOutputId || null,
    target_input_lang_id: translateInputId || null,
  };
};

export const responseSettingsNormalizer = (data) => {
  const {
    engine,
    max_tokens,
    temperature,
    top_p,
    frequency_penalty,
    presence_penalty,
    best_of,
    stop,
    logit_bias_hr,
    n,
    name,
    prompt,
    is_translate_input,
    is_translate_output,
    target_output_lang_id,
    target_input_lang_id,
  } = data;

  const formattedStopSequences = stop
    ? stop.map((item) => {
        return item.replace(/\n/g, "↵");
      })
    : [];

  return {
    name,
    engine,
    temperature,
    responseLength: max_tokens,
    topP: top_p,
    frequencyPenalty: frequency_penalty,
    presencePenalty: presence_penalty,
    bestOf: best_of,
    n,
    logitBias: logit_bias_hr || [],
    stopSequences: formattedStopSequences,
    prompt,
    isTranslateInput: is_translate_input,
    isTranslateOutput: is_translate_output,
    translateInputId: target_input_lang_id,
    translateOutputId: target_output_lang_id,
  };
};
