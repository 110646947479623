import { PresetsSelector } from "../../basic/PresetsSelector/PresetsSelector";
import { CustomCard } from "../../basic/CustomCard/CustomCard";
import { makeStyles } from "@material-ui/core/styles";
import { RequestEngineSelect } from "./RequestEngineSelect";
import { RequestSlider } from "./RequestSlider";
import { RequestStopSequencesSelector } from "./RequestStopSequencesSelector";
import { RequestLogitBiasInput } from "./RequestLogitBiasInput";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "./mentions.module.css";
import { MentionsInput, Mention } from "react-mentions";
import { checkAttributesMatch } from "../../../utils/checkAttributesMatch";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import {
  updatePresetRequest,
  getLanguages,
  getRest,
  updatePresetPropertyRequest,
} from "../../../utils/apiCalls";
import Checkbox from "@material-ui/core/Checkbox";
import { Paper, Select } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import { selectedCollectionIdSelector } from "../../../features/collections/collectionsSlice";

const useStyles = makeStyles((theme) => ({
  stepHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "24px",
  },
  cardTitle: {
    fontSize: "20px",
  },
  editor: {
    minHeight: "200px",
    backgroundColor: "#fff",
    border: "1px solid",
    padding: "0 16px",
    fontSize: "18px",
    cursor: "text",
  },
  promptContainer: {
    width: "100%",
  },
  settingsContainer: {
    minWidth: "400px",
  },
  attributeButton: {
    marginBottom: "16px",
    marginRight: "16px",
  },
  languages: {
    display: "flex",
    justifyContent: "space-between",
  },
  languages__item: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  infoSvg: {
    marginLeft: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const tooltips = {
  responseLength: `Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive.`,
  temperature: `Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive.`,
  topP: `Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered.`,
  frequencyPenalty: `How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim`,
  presencePenalty: `How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics.`,
  bestOf: `Generates multiple completions server-side, and displays only the best. Streaming only works when set to 1. Since it acts as a multiplier on the number of completions, this parameters can eat into your token quota very quickly – use caution!`,
  stopSequences: `Up to four sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence.`,
  n: "How many completions to generate for each prompt. Note: Because this parameter generates many completions, it can quickly consume your token quota. Use carefully and ensure that you have reasonable settings for max_tokens and stop.",
};

export const RequestEditor = ({
  attributes,
  error,
  setAlert,
  selectedPreset,
  setSelectedPreset,
  setStep,
  isAdmin,
  setAttributes,
}) => {
  const [langList, setLangList] = useState([]);
  const [restResponse, setRestResponse] = useState({});
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoContent, setInfoContent] = useState(null);
  const classes = useStyles();
  const editorCursorPosition = useRef(0);
  const [requestSettings, setRequestSettings] = useState({
    engine: "davinci",
    responseLength: 64,
    temperature: 0.7,
    topP: 1,
    frequencyPenalty: 0,
    presencePenalty: 0,
    bestOf: 1,
    stopSequences: [],
    logitBias: [],
    n: 1,
    name: "",
    prompt: "",
    isTranslateInput: false,
    isTranslateOutput: false,
    translateInputId: "",
    translateOutputId: "",
  });
  const {
    engine,
    responseLength,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    bestOf,
    stopSequences,
    logitBias,
    n,
    editorState,
    prompt,
    isTranslateInput,
    isTranslateOutput,
    translateInputId,
    translateOutputId,
  } = requestSettings;

  const selectedCollectionId = useSelector(selectedCollectionIdSelector);

  const Info = ({ item }) => {
    return (
      <div className={classes.paper}>
        {/*<h2 id="simple-modal-title">Text in a modal</h2>*/}
        <div id="simple-modal-description">
          {restResponse.apiUrls && (
            <ReactJson
              src={item}
              name={false}
              indentWidth={4}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    getLanguages().then(({ data }) => setLangList(data));
    getRest().then(({ data }) => setRestResponse(data));
  }, []);

  useEffect(() => {
    if (!isTranslateInput) {
      handleSettingsChange("", "translateInputId");
    }
  }, [isTranslateInput]);

  useEffect(() => {
    if (!isTranslateOutput) {
      handleSettingsChange("", "translateOutputId");
    }
  }, [isTranslateOutput]);

  const getSuggestions = () => {
    return attributes.map((item) => ({
      display: item.toUpperCase(),
      id: item,
    }));
  };

  const handleSettingsChange = useCallback(
    (value, name) => {
      setRequestSettings((settings) => ({ ...settings, [name]: value }));
    },
    [setRequestSettings]
  );

  const handleSavePreset = async () => {
    if (!requestSettings.prompt) {
      setAlert({ type: "error", text: "Please provide GPT-3 prompt" });
    } else {
      await updatePresetRequest({
        data: requestSettings,
        id: selectedPreset,
        collectionId: selectedCollectionId,
      });
      setAlert({ type: "success", text: "Preset config saved" });
    }
  };

  const handleEditPresetName = async (name) => {
    handleSettingsChange(name, "name");

    await updatePresetPropertyRequest({
      data: { name },
      id: selectedPreset,
      collectionId: selectedCollectionId,
    });

    setAlert({ type: "success", text: "Preset name saved" });
  };

  const onProceedClick = async () => {
    if (!checkAttributesMatch(prompt, attributes)) {
      setAlert({ type: "error", text: "This preset doesn't fit to JSON" });
      return;
    }
    await handleSavePreset();
    setStep(3);
  };

  const onAttributeClick = (attribute) => {
    const cursor = editorCursorPosition.current;
    const start = prompt.slice(0, cursor);
    const end = prompt.slice(cursor, prompt.length);
    const result = start + `@(${attribute})` + end;
    handleSettingsChange(result, "prompt");
  };

  useEffect(() => {
    if (bestOf < n) {
      setAlert({ type: "error", text: "“N” must be lesser than “Best Of”" });
      setRequestSettings((settings) => ({ ...settings, n: bestOf }));
    }
  }, [n]);

  useEffect(() => {
    if (bestOf < n) {
      setAlert({ type: "error", text: "“Best Of” must be greater than “N”" });
      setRequestSettings((settings) => ({ ...settings, bestOf: n }));
    }
  }, [bestOf]);

  return (
    <>
      <div className={classes.stepHeader}>
        <PresetsSelector
          allowActions
          attributes={attributes}
          editorState={editorState}
          error={error}
          isAdmin={isAdmin}
          requestSettings={requestSettings}
          selectedPreset={selectedPreset}
          handleEditName={handleEditPresetName}
          handleSavePreset={handleSavePreset}
          handleSettingsChange={handleSettingsChange}
          setAlert={setAlert}
          setAttributes={setAttributes}
          setRequestSettings={setRequestSettings}
          setSelectedPreset={setSelectedPreset}
        />
        <Button variant="contained" color="primary" onClick={onProceedClick}>
          Proceed to Preview
        </Button>
      </div>
      <Divider style={{ margin: "0 -48px 32px" }} />
      <Grid container spacing={8} justifyContent="space-between" wrap="nowrap">
        <Grid item className={classes.promptContainer}>
          <CustomCard
            header={
              <Typography className={classes.cardTitle} variant="h6">
                GPT-3 prompt
              </Typography>
            }
          >
            {attributes.map((attribute) => (
              <Chip
                icon={<AddIcon />}
                onClick={() => onAttributeClick(attribute)}
                label={attribute}
                className={classes.attributeButton}
                key={attribute}
              />
            ))}
            <MentionsInput
              value={prompt}
              className="mentions"
              classNames={classNames}
              onBlur={(e) =>
                (editorCursorPosition.current = e.target.selectionStart)
              }
              onChange={(e, newValue) =>
                handleSettingsChange(newValue, "prompt")
              }
            >
              <Mention
                data={getSuggestions()}
                className={classNames.mentions__mention}
                markup={"@(__id__)"}
                displayTransform={(id, display) => `@"${display}"`}
              />
            </MentionsInput>
            <div className={classes.languages}>
              <div className={classes.languages__item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTranslateInput}
                      color="primary"
                      onChange={(e) =>
                        handleSettingsChange(
                          e.target.checked,
                          "isTranslateInput"
                        )
                      }
                    />
                  }
                  label="Translate JSON Input to"
                  labelPlacement="start"
                />
                <Select
                  disabled={!isTranslateInput}
                  value={translateInputId}
                  input={<OutlinedInput />}
                  onChange={(e) =>
                    handleSettingsChange(e.target.value, "translateInputId")
                  }
                >
                  {langList.map(({ name, id }) => (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.languages__item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTranslateOutput}
                      color="primary"
                      onChange={(e) =>
                        handleSettingsChange(
                          e.target.checked,
                          "isTranslateOutput"
                        )
                      }
                    />
                  }
                  label="Translate Output to"
                  labelPlacement="start"
                />
                <Select
                  disabled={!isTranslateOutput}
                  value={translateOutputId}
                  input={<OutlinedInput />}
                  onChange={(e) =>
                    handleSettingsChange(e.target.value, "translateOutputId")
                  }
                >
                  {langList.map(({ name, id }) => (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </CustomCard>
          <Paper style={{ padding: "24px", marginTop: "30px" }}>
            <div className="API-Key">
              <span style={{ fontWeight: "bold" }}>API-Key:</span>{" "}
              {restResponse.apiKey}
            </div>
            <div className="API-Url" style={{ marginTop: "20px" }}>
              <span style={{ fontWeight: "bold" }}>API-Url:</span>
              {restResponse.apiUrls &&
                restResponse.apiUrls.map((item) => {
                  return (
                    <div
                      key={item.url}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p style={{ margin: "3px 0" }}>{item.url}</p>
                      <InfoOutlined
                        className={classes.infoSvg}
                        onClick={() => {
                          setOpenInfoModal(true);
                          setInfoContent(item);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </Paper>
        </Grid>
        <Grid item className={classes.settingsContainer}>
          <CustomCard
            header={
              <Typography className={classes.formTitle} variant="h6">
                GPT-3 settings
              </Typography>
            }
          >
            {useMemo(
              () => (
                <RequestEngineSelect
                  value={engine}
                  setRequestSettings={setRequestSettings}
                />
              ),
              [engine]
            )}
            {useMemo(
              () => (
                <RequestSlider
                  label="Response length"
                  value={responseLength}
                  minValue={1}
                  maxValue={2048}
                  valueStep={20}
                  handleChange={handleSettingsChange}
                  tooltipText={tooltips.responseLength}
                  name="responseLength"
                />
              ),
              [responseLength]
            )}
            {useMemo(
              () => (
                <RequestSlider
                  label="Temperature"
                  value={temperature}
                  minValue={0}
                  maxValue={1}
                  valueStep={0.01}
                  handleChange={handleSettingsChange}
                  tooltipText={tooltips.temperature}
                  name="temperature"
                />
              ),
              [temperature]
            )}
            {useMemo(
              () => (
                <RequestSlider
                  label="Top P"
                  value={topP}
                  minValue={0}
                  maxValue={1}
                  valueStep={0.01}
                  handleChange={handleSettingsChange}
                  tooltipText={tooltips.topP}
                  name="topP"
                />
              ),
              [topP]
            )}
            {useMemo(
              () => (
                <RequestSlider
                  label="Frequency penalty"
                  value={frequencyPenalty}
                  minValue={0}
                  maxValue={2}
                  valueStep={0.01}
                  handleChange={handleSettingsChange}
                  tooltipText={tooltips.frequencyPenalty}
                  name="frequencyPenalty"
                />
              ),
              [frequencyPenalty]
            )}
            {useMemo(
              () => (
                <RequestSlider
                  label="Presence penalty"
                  value={presencePenalty}
                  minValue={0}
                  maxValue={2}
                  valueStep={0.01}
                  handleChange={handleSettingsChange}
                  name="presencePenalty"
                  tooltipText={tooltips.presencePenalty}
                />
              ),
              [presencePenalty]
            )}
            {useMemo(
              () => (
                <RequestSlider
                  label="Best Of"
                  value={bestOf}
                  minValue={1}
                  maxValue={20}
                  valueStep={1}
                  handleChange={handleSettingsChange}
                  name="bestOf"
                  tooltipText={tooltips.bestOf}
                />
              ),
              [bestOf]
            )}
            {useMemo(
              () => (
                <RequestSlider
                  label="N"
                  value={n}
                  minValue={1}
                  maxValue={20}
                  valueStep={1}
                  handleChange={handleSettingsChange}
                  name="n"
                  tooltipText={tooltips.n}
                />
              ),
              [n]
            )}
            {useMemo(
              () => (
                <RequestStopSequencesSelector
                  setRequestSettings={setRequestSettings}
                  stopSequences={stopSequences}
                />
              ),
              [stopSequences]
            )}
            {/* {useMemo(
              () => (
                <RequestNInput
                  value={n}
                  handleSettingsChange={handleSettingsChange}
                />
              ),
              [n]
            )} */}
            {useMemo(
              () => (
                <RequestLogitBiasInput
                  values={logitBias}
                  setRequestSettings={setRequestSettings}
                />
              ),
              [logitBias]
            )}
          </CustomCard>
        </Grid>
      </Grid>
      <Modal
        open={openInfoModal}
        onClose={() => setOpenInfoModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <Info item={infoContent} />
        </div>
      </Modal>
    </>
  );
};
