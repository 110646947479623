import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    marginLeft: -7,

    "& > *": {
      marginLeft: 7,
      marginBottom: 7,
    },
  },
});

export default useStyles;
