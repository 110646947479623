import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { useState } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  cardsContainer: {
    margin: "8px 0 24px",
    display: "flex",
    flexWrap: "wrap",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  label: {
    fontSize: "14px",
    color: "black",
    marginRight: "6px",
  },
}));

export const RequestStopSequencesSelector = ({
  setRequestSettings,
  stopSequences,
}) => {
  const classes = useStyles();
  const [sequenceInput, setSequenceInput] = useState("");

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: "16px",
      border: "1px solid #dadde9",
      padding: "16px",
    },
  }))(Tooltip);

  const handleInput = (e) => {
    if (e.key === "Tab") {
      if (!sequenceInput) return;
      e.preventDefault();
      setRequestSettings((settings) => ({
        ...settings,
        stopSequences: [...settings.stopSequences, sequenceInput],
      }));
      setSequenceInput("");
    }

    if (e.key === "Enter") {
      e.preventDefault();
      setSequenceInput((value) => `${value}↵`);
    }
  };

  const handleOnChange = (e) => {
    setSequenceInput(e.target.value);
  };

  const handleDeleteSequence = (id) => {
    setRequestSettings((settings) => ({
      ...settings,
      stopSequences: settings.stopSequences.filter(
        (seq, index) => id !== index
      ),
    }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.labelContainer}>
        <InputLabel htmlFor="sequences-select" className={classes.label}>
          Stop sequences
        </InputLabel>
        <CustomTooltip
          placement="left"
          title="Up to four sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence."
        >
          <InfoOutlinedIcon fontSize="small" />
        </CustomTooltip>
      </div>

      <Typography variant="caption">Enter sequence and press Tab</Typography>
      <Input
        id="sequences-select"
        value={sequenceInput}
        onChange={handleOnChange}
        onKeyDown={handleInput}
        multiline
      />
      <CustomTooltip
        placement="left"
        open={!!sequenceInput}
        title={`Add "${sequenceInput}"`}
      >
        <div className={classes.cardsContainer}>
          {stopSequences?.map((seq, i) => (
            <Chip
              label={seq}
              key={seq + i}
              onDelete={() => handleDeleteSequence(i)}
            />
          ))}
        </div>
      </CustomTooltip>
    </div>
  );
};
