import React from "react";
import useStyles from "./EditableInput.style";
import TextField from "@material-ui/core/TextField";
import cn from "classnames";

const EditableTextField = ({ mode, ...props }) => {
  const c = useStyles();
  const { value } = props;

  if (mode === "edit") {
    return <TextField InputProps={{ className: c.root }} {...props} />;
  }

  return <div className={cn(c.root, c.rootDiv)}>{value}</div>;
};

export default EditableTextField;
