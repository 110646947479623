import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import ImageUploader from "../ImageUploader";
import useLanguagesList from "../../../hooks/useLanguagesList";
import {
  addSelectedLanguages,
  deleteSelectedLanguage,
  getSettings,
  getUploadedImages,
  imagesSelector,
  isLoadingImagesSelector,
  imageSettingsSelector,
} from "../imageTaggingSlice";
import ChipsPicker from "../../../components/basic/ChipsPicker";
import ImageTools from "../ImageSettings";
import Card from "@material-ui/core/Card";
import Masonry from "react-masonry-component";

import useStyles from "./ImageTagging.style";
import Divider from "@material-ui/core/Divider";
import ImageTaggingControls from "../ImageTaggingControls";
import TaggingProgressBar from "../TaggingProgressBar";

const ImageTagging = ({}) => {
  const c = useStyles();

  const dispatch = useDispatch();

  const langList = useLanguagesList();

  const isLoading = useSelector(isLoadingImagesSelector);
  const { languages: selectedLanguages } = useSelector(imageSettingsSelector);
  const images = useSelector(imagesSelector);

  useEffect(() => {
    dispatch(getUploadedImages());
  }, [dispatch]);

  useEffect(() => {
    images.length && dispatch(getSettings());
  }, [dispatch, images]);

  return (
    <div className={c.root}>
      {isLoading ? (
        <div className={c.loaderWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <TaggingProgressBar />
          <ImageTaggingControls />
          <Divider />
          <Card className={c.controls}>
            <ImageUploader />
            <div>
              <p>Translate into the following languages:</p>
              <ChipsPicker
                list={langList}
                selectedList={selectedLanguages}
                onAdd={(id) => dispatch(addSelectedLanguages(id))}
                onDelete={(id) => dispatch(deleteSelectedLanguage(id))}
              />
            </div>
          </Card>
          <ImageTools />
          <Masonry className={c.photoList} elementType={"ul"}>
            {images.map((image, i) => (
              <li className={c.photoItem} key={i}>
                <img src={image} alt={i} />
              </li>
            ))}
          </Masonry>
        </>
      )}
    </div>
  );
};

export default ImageTagging;
