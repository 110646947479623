import React, { useState, useEffect } from "react";
import useStyles from "./CustomAlert.style";
import MuiAlert from "@material-ui/lab/Alert";

const CustomAlert = ({ type, isShow, children }) => {
  const c = useStyles();
  const [isInnerShow, setIsInnerShow] = useState(false);

  useEffect(() => {
    setIsInnerShow(isShow);

    setTimeout(() => {
      setIsInnerShow(false);
    }, 2000);
  }, [isShow]);

  return (
    <>
      {isInnerShow && (
        <MuiAlert className={c.alert} severity={type} elevation={6} variant="filled">
          {children}
        </MuiAlert>
      )}
    </>
  );
};

export default CustomAlert;