import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    minHeight: "100%",
    height: "100%",
    position: "relative",

    "& > * + *": {
      marginTop: 15,
    },
  },
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  controls: {
    display: "flex",
    padding: 24,

    "& > * + *": {
      marginLeft: 50,
    },
  },
  photoList: {
    padding: 0,
    width: "100%",
  },
  photoItem: {
    display: "flex",
    width: "calc(25% - 10px)",
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.12)",
    margin: 5,

    "& img": {
      width: "100%",
    },

    "@media (max-width: 1366px)": {
      width: "calc(33.33% - 10px)",
    },

    "@media (max-width: 992px)": {
      width: "calc(50% - 10px)",
    },

    "@media (max-width: 600px)": {
      width: "calc(100% - 10px)",
    },
  },
});

export default useStyles;
