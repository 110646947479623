import React, { useCallback, useEffect, useState } from "react";
import {
  FormLabel,
  Select,
  Slider,
  TextField,
  FormControl,
  Button,
  Box,
  MenuItem,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Card,
  CircularProgress,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import PhotoSizeSelectLargeIcon from "@material-ui/icons/PhotoSizeSelectLarge";
import { useDispatch, useSelector } from "react-redux";
import {
  imageSettingsSelector,
  imagesSelector,
  setFileId,
  setImageSetting,
} from "../imageTaggingSlice";
import useStyles from "./ImageTools.style";
import {
  getPreviewImageWithPresets,
  setNewPreset,
} from "../../../utils/apiCalls";
import cn from "classnames";
import debounce from "lodash.debounce";

const resizeOptions = ["fit", "fill", "fill-down", "force", "auto"];
const gravityOptions = [
  "no",
  "so",
  "ea",
  "we",
  "noea",
  "nowe",
  "soea",
  "sowe",
  "ce",
  "sm",
  "obj",
];
const extensionOptions = ["png", "jpg", "webp"];
const positionOptions = [
  "ce",
  "no",
  "so",
  "ea",
  "we",
  "noea",
  "nowe",
  "soea",
  "sowe",
  "re",
];

const UPDATE_DEBOUNCE_TIMEOUT = 500;

const ImageTools = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const state = useSelector(imageSettingsSelector);
  const images = useSelector(imagesSelector);

  const {
    height,
    is_min_height,
    width,
    is_min_width,
    is_enlarge,
    resize,
    gravity,
    extension,
    position,
    opacity,
    scale,
    is_watermark,
  } = state;

  const [imagePreview, setImagePreview] = useState(images[0]);
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [imagePreviewIsLoading, setImagePreviewIsLoading] = useState(false);

  const setSettings = (name, value) => {
    dispatch(setImageSetting({ name, value }));
  };

  const handleMeasureChange = (name, value) => {
    setSettings(name, value || null);
  };

  const onPreviewClick = () => {
    const newWindow = window.open("about:blank", "Preview");
    newWindow.document.write(`<img src=${imagePreview} alt='preview' />`);
  };

  const updateImagePreview = (presets, silent = true) => {
    setNewPreset(presets);

    if (!presets.url) {
      return;
    }

    if (!silent) {
      setImagePreviewIsLoading(true);
    }

    getPreviewImageWithPresets({
      ...presets,
      url: presets.url,
    })
      .then((res) => {
        setImagePreview(res.data);
      })
      .finally(() => {
        if (!silent) {
          setImagePreviewIsLoading(false);
        }
      });
  };

  const updateImagePreviewWithDebounce = useCallback(
    debounce(
      (presets) => updateImagePreview(presets, false),
      UPDATE_DEBOUNCE_TIMEOUT,
      {
        loading: true,
      }
    ),
    []
  );

  const changePreviewImage = () => {
    const imagesWithoutPreview = images.filter((item) => item !== currentImage);

    const randomImage =
      imagesWithoutPreview[
        Math.floor(Math.random() * imagesWithoutPreview.length)
      ];

    setCurrentImage(randomImage);

    updateImagePreviewWithDebounce({
      ...state,
      url: randomImage,
    });
  };

  const removePreviewOnClear = () => { 
    if (images.length === 0) {
      setImagePreview("");
      setCurrentImage("");
      dispatch(setFileId({}));
    }
  };

  const updateImagePreviewOnStateChange = useCallback(
    debounce(updateImagePreview, UPDATE_DEBOUNCE_TIMEOUT),
    []
  );

  useEffect(removePreviewOnClear, [images.length]);
  useEffect(() => {
    updateImagePreviewOnStateChange({
      ...state,
      url: !images.length ? '' : currentImage,
    });
  }, [state]);

  return (
    <Card className={classes.root}>
      <Box style={{ display: "flex" }}>
        <Box style={{ marginRight: "24px", minWidth: "600px" }}>
          <Box
            className={classes.row}
            style={{ justifyContent: "space-between" }}
          >
            <FormGroup style={{ width: "48%" }}>
              <TextField
                value={height || ""}
                type="number"
                label="Height"
                variant="outlined"
                size="small"
                onChange={(e) =>
                  handleMeasureChange("height", Number(e.target.value))
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"primary"}
                    checked={is_min_height}
                    onChange={(e) =>
                      setSettings("is_min_height", e.target.checked)
                    }
                  />
                }
                label="Min-height"
              />
            </FormGroup>
            <FormGroup style={{ width: "48%" }}>
              <TextField
                value={width || ""}
                label="Width"
                type="number"
                variant="outlined"
                size="small"
                onChange={(e) =>
                  handleMeasureChange("width", Number(e.target.value))
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"primary"}
                    checked={is_min_width}
                    onChange={(e) =>
                      setSettings("is_min_width", e.target.checked)
                    }
                  />
                }
                label="Min-width"
              />
            </FormGroup>
          </Box>
          <Divider className={classes.margins} />
          <Box>
            <FormGroup className={classes.row}>
              <FormControlLabel
                control={
                  <Checkbox
                    color={"primary"}
                    checked={is_enlarge}
                    onChange={(e) =>
                      setSettings("is_enlarge", e.target.checked)
                    }
                  />
                }
                label="Enlarge"
              />
            </FormGroup>
          </Box>
          <Divider className={classes.margins} />
          <Box className={`${classes.row} ${classes.margins}`}>
            <FormControl className={classes.select} size="small">
              <FormLabel>Gravity</FormLabel>
              <Select
                id="gravityImg-select"
                value={gravity}
                variant={"outlined"}
                onChange={(e) => setSettings("gravity", e.target.value)}
                style={{ width: "80%" }}
              >
                {gravityOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.select} size="small">
              <FormLabel>Resize</FormLabel>
              <Select
                value={resize}
                variant={"outlined"}
                onChange={(e) => setSettings("resize", e.target.value)}
                style={{ width: "80%" }}
              >
                {resizeOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.select} size="small">
              <FormLabel>Extension</FormLabel>
              <Select
                id="extensionImg-select"
                value={extension}
                variant={"outlined"}
                onChange={(e) => setSettings("extension", e.target.value)}
                style={{ width: "80%" }}
              >
                {extensionOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider className={classes.margins} />
          <Box>
            <FormGroup className={classes.row}>
              <FormControlLabel
                control={
                  <Checkbox
                    color={"primary"}
                    checked={is_watermark}
                    onChange={(e) =>
                      setSettings("is_watermark", e.target.checked)
                    }
                  />
                }
                label="Watermark"
              />
            </FormGroup>
          </Box>

          <div className={cn(classes.row, classes.watermarkRow)}>
            {is_watermark && (
              <>
                <FormControl
                  className={classes.select}
                  style={{ flex: "2" }}
                  size="small"
                >
                  <FormLabel>Position</FormLabel>
                  <Select
                    value={position}
                    variant={"outlined"}
                    onChange={(e) => setSettings("position", e.target.value)}
                  >
                    {positionOptions.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box style={{ margin: "15px", flex: "1" }}>
                  <Typography id="input-slider" align="left">
                    Opacity
                  </Typography>
                  <Slider
                    classes={{
                      valueLabel: classes.valueLabel,
                    }}
                    size="small"
                    value={opacity}
                    onChange={(e, value) => setSettings("opacity", value)}
                    aria-label="Small"
                    valueLabelDisplay="on"
                    step={0.1}
                    min={0}
                    max={1}
                  />
                </Box>
                <Box style={{ margin: "15px", flex: "1" }}>
                  <Typography id="input-slider" align="left">
                    Scale
                  </Typography>
                  <Slider
                    classes={{
                      valueLabel: classes.valueLabel,
                    }}
                    size="small"
                    value={scale}
                    onChange={(e, value) => setSettings("scale", value)}
                    aria-label="Small"
                    valueLabelDisplay="on"
                    step={0.1}
                    min={0}
                    max={1}
                  />
                </Box>
              </>
            )}
          </div>
        </Box>
        <Box style={{ width: "100%" }}>
          {(Boolean(imagePreview) || Boolean(images.length)) >= 1 && (
            <>
              <>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {images.length >= 1 && (
                    <Button
                      color={"primary"}
                      startIcon={<RefreshIcon />}
                      onClick={changePreviewImage}
                    >
                      Refresh Image Preview
                    </Button>
                  )}
                  <Button
                    color={"primary"}
                    startIcon={<PhotoSizeSelectLargeIcon />}
                    onClick={onPreviewClick}
                  >
                    Show Image in fullscreen
                  </Button>
                </Box>
                <Box
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {Boolean(imagePreview) &&
                    (imagePreviewIsLoading ? (
                      <CircularProgress />
                    ) : (
                      <img
                        alt="preview"
                        src={imagePreview}
                        style={{
                          display: "block",
                          maxWidth: "670px",
                          maxHeight: "370px",
                          margin: "0 auto",
                          alignSelf: "center",
                        }}
                      />
                    ))}
                </Box>
              </>
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default ImageTools;
